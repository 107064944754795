import mappedCountriesJson from '../../../src/assets/json-administration-profiles/availableCountry.map.value.json';
import mappedCountriesRegionsJson from '../../../src/assets/json-administration-profiles/availableCountryRegion.map.value.json';

export function isCountry(uuid: string): boolean {
	return !!mappedCountriesJson[uuid as keyof typeof mappedCountriesJson];
}

export function isCountryRegion(uuid: string): boolean {
	return !!mappedCountriesRegionsJson[uuid as keyof typeof mappedCountriesRegionsJson];
}
