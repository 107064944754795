import { apolloAdapter } from '@warehouse/shared/infra';
import { firstValueFrom, map, Observable } from 'rxjs';
import { EDIT_TITLE_V2, GET_POSTER, GET_TITLE_V2, GetPosterOutput, GetTitleOutput } from '@warehouse/graphql';
import { EditTitleInput, PollOptions, QueryOptions, TitleFull, TitleRepository } from '@warehouse/title/core';
import { singletonFactory } from '@warehouse/shared/util';
import { Title } from '@nexspec/warehouse-shared-types';
import { Poster } from '../core/models/poster';
import { posterGQLResponseToPoster } from './poster';
import { titleGQLResponseToTitleFull } from './title';

export class GqlTitleRepository implements TitleRepository {
	private _apolloAdapter = apolloAdapter;

	watchTitle(uuid: string, { pollInterval }: PollOptions = {}): Observable<TitleFull> {
		const response$ = this._apolloAdapter.watchObservableQuery<{ getTitleV2: GetTitleOutput }>({
			query: GET_TITLE_V2,
			variables: {
				uuid,
			},
			pollInterval,
		});

		return response$.pipe(
			map((apolloResponse) =>
				titleGQLResponseToTitleFull({
					...apolloResponse.data.getTitleV2,
					state: JSON.parse(apolloResponse.data.getTitleV2.state) as Title,
				}),
			),
		);
	}

	getTitle(uuid: string): Promise<TitleFull> {
		return firstValueFrom(this.watchTitle(uuid));
	}

	async refetchTitle(uuid: string, options: QueryOptions = {}) {
		await this._apolloAdapter.client.query<{ getTitleV2: GetTitleOutput }>({
			query: GET_TITLE_V2,
			variables: {
				uuid,
			},
			context: {
				fetchOptions: {
					signal: options.signal,
				},
			},
			fetchPolicy: 'network-only',
		});
	}

	async editTitle({ type, uuid, edits }: EditTitleInput, options: { signal?: AbortSignal } = {}): Promise<TitleFull> {
		const mutateResponse = await this._apolloAdapter.client.mutate({
			mutation: EDIT_TITLE_V2,
			variables: {
				input: {
					edits,
					type,
					uuid,
				},
			},
			context: {
				fetchOptions: {
					signal: options.signal,
				},
			},
			update(cache, { data }) {
				try {
					if (!data?.editTitleV2) return;

					const title = data?.editTitleV2;

					cache.writeQuery({
						query: GET_TITLE_V2,
						variables: {
							uuid: title?.uuid,
						},
						data: {
							getTitleV2: title,
						},
					});
				} catch (error) {
					console.error(error);
				}
			},
		});

		// Hypothesis: It should never throw here, because the mutation should always return data
		// In case of errors (abort,  errors in mutation, ...), the mutate function should throw
		// We do not understand why the mutation would not return data
		if (!mutateResponse.data?.editTitleV2) throw new Error('No data returned from editTitle mutation');

		const { indexed, state } = mutateResponse.data.editTitleV2;

		const title = JSON.parse(state) as Title;

		return titleGQLResponseToTitleFull({ indexed, state: title, uuid });
	}

	watchPoster(uuid: string, { pollInterval }: { pollInterval?: number } = {}): Observable<Poster> {
		const response$ = this._apolloAdapter.watchObservableQuery<{ getPoster: GetPosterOutput }>({
			query: GET_POSTER,
			variables: {
				uuid,
			},
			pollInterval,
		});

		return response$.pipe(map((apolloResponse) => posterGQLResponseToPoster(apolloResponse.data.getPoster)));
	}

	getPoster(uuid: string): Promise<Poster> {
		return firstValueFrom(this.watchPoster(uuid));
	}

	async refetchPoster(uuid: string, options: QueryOptions = {}): Promise<void> {
		await this._apolloAdapter.client.query<{ getPoster: GetPosterOutput }>({
			query: GET_POSTER,
			variables: {
				uuid,
			},
			context: {
				fetchOptions: {
					signal: options.signal,
				},
			},
			fetchPolicy: 'network-only',
		});
	}
}

export const titleRepositorySingleton = singletonFactory<TitleRepository>({ factory: () => new GqlTitleRepository() });
