import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';
import styled, { css } from 'styled-components';

// TYPES
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// LIBRARY
import { OptionalNullInherited, resetInheritedArrayValues, titlePaths, updateValue } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import { computeMaxListingOrder, titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { TableTextInput } from '../../../../src/views/title/tab/productMetadata/style';
import computeErrors from '../../../../src/views/title/utils/computeErrors';

// JSON
import languagesJSON from '../../../../src/assets/json-administration-profiles/availableBasicOriginalLanguage.json';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';
import useTooltip from '../../../../src/utils/hooks/useTooltips';
import { createOriginalLanguage } from '../../core/models/original-languages';

interface OriginalLanguagesTableProps {
	originalLanguages: OriginalLanguage[];
	setValue: Dispatch<SetStateAction<OptionalInherited<OriginalLanguage[]>>>;
	commit: () => void;
	isInherited: boolean;
}

const defaultLocale = languagesJSON?.find(({ name }) => name === 'en-US');

export function OriginalLanguagesTable({
	originalLanguages,
	setValue,
	commit,
	isInherited,
}: OriginalLanguagesTableProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const listingOrderTooltip = useTooltip('coreMetadata.basic.originalLanguages.listingOrder');
	const localeTooltip = useTooltip('coreMetadata.basic.originalLanguages.originalLanguage');

	const originalLanguagesColumns: CustomColumnDef<OriginalLanguage>[] = useMemo(
		() => [
			{
				header: 'Listing Order',
				tooltip: listingOrderTooltip?.tooltip,
				accessorFn: (row) => row?.listingOrder,
				width: 50,
				cell: (info) => ListingOrderCell(info),
			},
			{
				id: 'locale',
				tooltip: localeTooltip?.tooltip,
				header: 'Locale',
				cell: (info) => LocaleCell(info),
				accessorFn: (row) => row?.originalLanguage,
			},
			{
				header: 'Mode',
				id: 'mode',
				cell: (info) => ModeSelector(info),
			},
		],
		[listingOrderTooltip?.tooltip, localeTooltip?.tooltip],
	);

	const onRowAdd = () => {
		const newListingOrder = computeMaxListingOrder(originalLanguages) + 1;
		if (defaultLocale) {
			const newOriginalLanguage = createOriginalLanguage({
				listingOrder: newListingOrder,
			});

			setValue((prev) => ({
				...prev,
				displayValue: [...(prev?.displayValue || []), newOriginalLanguage],
			}));
			commit();
		}
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		const updatedOriginalLanguages = originalLanguages.filter(
			(_, index) => !selectedIndexes.includes(index.toString()),
		);
		setValue(resetInheritedArrayValues<OriginalLanguage>(updatedOriginalLanguages, title));
		commit();
	};

	const errors: { [key: string]: number[] } = useMemo(
		() => ({
			listingOrder: computeErrors<OriginalLanguage>(originalLanguages || [], 'listingOrder'),
			originalLanguage: computeErrors<OriginalLanguage>(originalLanguages || [], 'originalLanguage'),
		}),
		[originalLanguages],
	);

	return (
		<Wrapper>
			<TitleEditableField
				label="Original Languages"
				path={titlePaths.originalLanguages}
				forcedEditMode
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<OriginalLanguage>
				selectRowModeByDefault
				enableAddMultipleRows={false}
				showBottomActionsBar
				tableStyle="border"
				columns={originalLanguagesColumns}
				data={originalLanguages}
				onRowAdd={onRowAdd}
				onRowDelete={(selectedIndexes) => onRowDelete(selectedIndexes)}
				errors={errors}
				editMode
				customPropsCell={{ setValue, commit, isInherited }}
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div(
	() => css`
		.title-editable-field {
			padding: 0;
		}
		.title-editable-field-label {
			font-size: 16px;
		}
	`,
);

function ListingOrderCell(info: CellContext<any, any>) {
	const { getValue, table, row } = info;
	const value = getValue();
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	const onChange = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			const newValue = parseInt(evt.target.value, 10);
			setValue((prev: OptionalInherited<OriginalLanguage[]>) => {
				const updatedDisplayValue = prev.displayValue?.map((item, index) => {
					if (index === row.index) {
						return {
							...item,
							listingOrder: Number.isNaN(newValue) ? 0 : Math.max(newValue, 0),
						};
					}
					return item;
				});
				return {
					...prev,
					displayValue: updatedDisplayValue,
				};
			});
		},
		[row.index, setValue],
	);

	const onBlur = useCallback(() => {
		commit();
	}, [commit]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					variant={isInherited ? 'prefilled' : 'default'}
					style={{ width: '124px', boxSizing: 'border-box' }}
					type="number"
					onBlur={onBlur}
					value={value?.toString() || ''}
					onChange={onChange}
				/>
			</CellWrapper>
		),
		[isInherited, onBlur, onChange, value],
	);
}

function LocaleCell(info: CellContext<any, any>) {
	const { getValue, table, row } = info;
	const value = getValue();
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	return (
		<CellWrapper>
			<DropdownV2
				withSearch
				variant={isInherited ? 'prefilled' : 'default'}
				value={value}
				transparentBorder
				getPopupContainer={() => document.body}
				backgroundColor="transparent"
				onChange={(e) => {
					setValue((prev: OptionalInherited<OriginalLanguage[]>) => {
						const updatedDisplayValue = prev.displayValue?.map((item, index) => {
							if (index === row.index) {
								return { ...item, originalLanguage: e };
							}
							return item;
						});
						return {
							...prev,
							displayValue: updatedDisplayValue,
						};
					});
					commit();
				}}
				options={
					languagesJSON?.map((locale: any) => ({
						label: locale.label,
						value: locale.uuid,
					})) || []
				}
			/>
		</CellWrapper>
	);
}

function ModeSelector(info: CellContext<any, any>) {
	const { row, table } = info;
	const { spoken, written, signed } = row.original;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	const selectedModes: string[] = [];
	if (spoken) selectedModes.push('spoken');
	if (written) selectedModes.push('written');
	if (signed) selectedModes.push('signed');

	const handleSelect = (key: 'spoken' | 'signed' | 'written') => {
		setValue((prev: OptionalInherited<OriginalLanguage[]>) =>
			updateModeInOriginalLanguagesWithInheritance({ key, currentOriginalLanguages: prev, indexToUpdate: row.index }),
		);
	};

	return (
		<CellWrapper>
			<DropdownV2
				onBlur={() => commit()}
				transparentBorder
				variant={isInherited ? 'prefilled' : 'default'}
				backgroundColor="transparent"
				getPopupContainer={() => document.body}
				mode="multiple"
				options={[
					{ value: 'spoken', label: 'Spoken' },
					{ value: 'written', label: 'Written' },
					{ value: 'signed', label: 'Signed' },
				]}
				value={selectedModes}
				onSelect={handleSelect as (key: string) => void}
				onDeselect={handleSelect as (key: string) => void}
			/>
		</CellWrapper>
	);
}

function updateModeInOriginalLanguagesWithInheritance({
	key,
	currentOriginalLanguages,
	indexToUpdate,
}: {
	key: 'spoken' | 'signed' | 'written';
	currentOriginalLanguages: OptionalInherited<OriginalLanguage[]>;
	indexToUpdate: number;
}): OptionalNullInherited<OriginalLanguage[]> {
	const newOriginalLanguages = updateModeInOriginalLanguages({
		key,
		currentOriginalLanguages: currentOriginalLanguages.displayValue,
		indexToUpdate,
	});

	return updateValue(newOriginalLanguages, currentOriginalLanguages);
}

export function updateModeInOriginalLanguages({
	key,
	currentOriginalLanguages,
	indexToUpdate,
}: {
	key: 'spoken' | 'signed' | 'written';
	currentOriginalLanguages?: OriginalLanguage[];
	indexToUpdate: number;
}): OriginalLanguage[] | undefined {
	return currentOriginalLanguages?.map((originalLanguage, index) => {
		if (index === indexToUpdate) {
			return { ...originalLanguage, [key]: originalLanguage[key] ? undefined : true };
		}
		return originalLanguage;
	});
}
