import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { alpha, Tab } from '@mui/material';

// HOOKS
import { FeatureId, isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import {
	TitleEditorStoreEffects,
	TitleEditorStoreProvider,
	titleEditorStoreSelector,
	usePoster,
	useTitle,
	useTitleEditorStore,
} from '@warehouse/title/domain';
import { WorkType } from '@warehouse/title/core';
import { CenteredNxLoader } from '@warehouse/shared/ui';
import { EditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import useWorkTypes from '../../utils/hooks/titles/useWorkTypes';
import { useStateQuery } from '../../utils/hooks/useStateQuery';

// LIBRARY
import Tabs from '../../components/library/Tabs';
import TitleOverviewLoader from '../../components/titles/TitleOverview/TitleOverview';
import { useURLFragment } from '../../utils/hooks/useURLFragment';
import { useHasEpisodeChildren } from './hooks/useHasEpisodeChildren';
import { ProductMetadataTab } from './tab/productMetadata';
import TitleSettings from './TitleSettings/TitleSettings';
import { Relationships } from './tab/relationships';
import ActivityTab from './tab/activity';
import ArtworkTab from './tab/artwork';
import TracksTab from './tab/tracks/TracksTab';
import OrdersTab from './tab/orders/OrdersTab';
import UnsavedChangesContextProvider, { UnsavedChangesContext } from './contexts/UnsavedChangesContext';
import { ChildrenTab } from './tab/children-tab';
import { useChildrenTabName } from './hooks/useChildrenTabName';
import { useRequiredParam } from '../../../libs/shared/util/use-required-params';
import TitleAutoSaveQueueContextProvider from './contexts/TitleAutoSaveQueueContext';
import TitleAutoSaveContextProvider from './contexts/TitleAutoSaveContext';
import titleSearchDisplayNameBuilder from '../../utils/titles/titleSearchDisplayNameBuilder';

const Wrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		overflow-y: auto;
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const BackdropWrapper = styled.div<{ url?: string }>(
	({ url }) => css`
		background-color: #dde0e3;
		${url &&
		css`
			background-image: url('${url}');
		`}
		background-position: center;
		background-size: cover;
		width: 100%;
	`,
);

const BackdropContainer = styled.div(
	() => css`
		backdrop-filter: blur(8px);
		display: flex;
		height: 100%;
	`,
);

const GlobalStyleTitleOverviewShadow = createGlobalStyle(
	() => css`
		.title-overview-shadow {
			border-radius: 8px;
			box-shadow: 0 30px 40px ${alpha('#000000', 0.5)};
		}
	`,
);

const TitleOverviewWrapper = styled.div<{ showBoxShadow?: boolean }>(
	() => css`
		flex: 0 0 auto;
		position: sticky;
		top: 0;
		z-index: 20; // Needed to be above the SimpleTable
	`,
);

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const ChildrenTabWrap = styled.div(
	() => css`
		& .MuiTab-root {
			width: 100%;
		}
	`,
);

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<ChildrenTabWrap
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ height: '95%' }}
		>
			{value === index && children}
		</ChildrenTabWrap>
	);
}

const TabsWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	`,
);

export function AdminTitleView() {
	return (
		<TitleEditorStoreProvider>
			<TitleEditorStoreEffects />
			<EditPreventer>
				<TitleAutoSaveQueueContextProvider>
					<TitleAutoSaveContextProvider>
						<UnsavedChangesContextProvider>
							<AdminTitleViewLoader />
						</UnsavedChangesContextProvider>
					</TitleAutoSaveContextProvider>
				</TitleAutoSaveQueueContextProvider>
			</EditPreventer>
		</TitleEditorStoreProvider>
	);
}

function AdminTitleViewLoader() {
	const id = useRequiredParam('id');

	const { setTitleUuid } = useTitleEditorStore(titleEditorStoreSelector.actions);

	useEffect(() => {
		setTitleUuid(id);
	}, [id, setTitleUuid]);

	const isTitlePending = useTitleEditorStore(titleEditorStoreSelector.isPending);

	if (isTitlePending) return <CenteredNxLoader />;

	// This is a workaround to make sure the whole page and its components are re-rendered when the title changes
	return <AdminTitleViewComp key={id} />;
}

export function AdminTitleViewComp() {
	// state
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const [fragment, setFragment] = useURLFragment();
	const id = useRequiredParam('id');
	const [settingsOpen, setSettingsOpen] = useStateQuery<boolean>({
		queryName: 'settings',
		type: 'boolean',
	});
	const [selectedTab, setSelectedTab] = useState(-1);
	const titleOverviewWrapperRef = useRef<HTMLDivElement>(null);
	// hooks
	const { episodesUuid } = useWorkTypes();
	const { data: posterData } = usePoster();
	const title = useTitle({ uuid: id });
	const isTitleHidden = title.data?.readonly.titleStatus === 'hidden';
	const [hasEpisode, loadingHasEpisode] = useHasEpisodeChildren(id, isTitleHidden);
	const loading = !title.data || loadingHasEpisode;

	const titleWorkType = title.data?.readonly.workType;
	const workTypeToExclude = useMemo(
		() => [...(!hasEpisode && titleWorkType === WorkType.Series ? [episodesUuid()] : [])],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hasEpisode, titleWorkType],
	);
	const childrenTabName = useChildrenTabName(title.data, workTypeToExclude);

	// ref
	const wrapperRef = useRef<HTMLDivElement>(null);

	// effects
	const tabs = useMemo<TabDefinition[]>(
		() =>
			title.data
				? [
						...childrenTabName.map((tab) => ({
							defaultUrlState: tab.pluralLabel.toLowerCase(),
							label: tab.pluralLabel,
							component: <ChildrenTab tabProperties={tab} title={title.data!} />,
						})),
						{
							defaultUrlState: 'product-metadata/localized-info/default',
							label: 'Product Metadata',
							component: <ProductMetadataTab title={title.data} wrapperRef={wrapperRef} />,
						},
						...addTabIfVisible(
							{
								defaultUrlState: 'tracks',
								label: 'Tracks',
								component: <TracksTab wrapperRef={wrapperRef} />,
							},
							'title-tracks-tab',
						),
						...addTabIfVisible(
							{
								defaultUrlState: 'artwork',
								label: 'Artwork',
								component: <ArtworkTab />,
								disabled: false,
							},
							'title-artworks-tab',
						),
						...addTabIfVisible(
							{
								defaultUrlState: 'orders',
								label: 'Orders',
								component: <OrdersTab />,
							},
							'title-orders-tab',
						),
						{
							defaultUrlState: 'relationships',
							label: 'Relationships',
							component: <Relationships />,
							disabled: false,
						},
						...addTabIfVisible(
							{
								defaultUrlState: 'activity/comments',
								label: 'Activity',
								component: <ActivityTab />,
								disabled: false,
							},
							'title-activity-tabs',
						),
				  ]
				: [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[title.data, childrenTabName],
	);

	// Put shadow on title overview when scrolling
	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = (wrapperRef.current && wrapperRef.current.scrollTop > 0) || false;
			if (titleOverviewWrapperRef.current) {
				if (isScrolled) {
					titleOverviewWrapperRef.current.classList.add('title-overview-shadow');
				} else {
					titleOverviewWrapperRef.current.classList.remove('title-overview-shadow');
				}
			}
		};

		wrapperRef.current?.addEventListener('scroll', handleScroll);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			wrapperRef.current?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const goToDefaultTab = () =>
		setFragment(childrenTabName.length ? childrenTabName[0].pluralLabel.toLowerCase() : 'orders');

	useEffect(() => {
		if (loading) return;
		if (!fragment) {
			goToDefaultTab();
			return;
		}
		const requestedTabName = fragment.split('/')[0];
		const tabIndex = tabs.findIndex((tab) => {
			const keyMatching = tab.defaultUrlState.split('/');
			if (!keyMatching.length) return false;
			return requestedTabName === keyMatching[0];
		});
		if (tabIndex >= 0) setSelectedTab(tabIndex);
		else goToDefaultTab();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fragment, loading]);

	if (loading || selectedTab < 0) {
		return <CenteredNxLoader />;
	}

	return (
		<>
			<BackdropWrapper url={posterData?.backdrop || posterData?.poster}>
				<BackdropContainer ref={wrapperRef}>
					<GlobalStyleTitleOverviewShadow />
					<Wrapper>
						<TitleOverviewWrapper ref={titleOverviewWrapperRef}>
							<TitleOverviewLoader onClickSettings={() => setSettingsOpen(true)} titleFull={title.data} />
						</TitleOverviewWrapper>
						<TabsWrapper>
							<Tabs
								value={selectedTab}
								onChange={(_, newSelectedTab) => {
									promptUserIfUnsavedChanges(() => setFragment(tabs[newSelectedTab]?.defaultUrlState));
								}}
							>
								{tabs.map((tab, index) => (
									<Tab
										style={{ maxWidth: '100%' }}
										key={index}
										label={tab.label}
										disabled={tab.disabled}
										{...a11yProps(index)}
									/>
								))}
							</Tabs>
							{tabs.map((tab, index) => (
								<TabPanel key={index} value={selectedTab} index={index}>
									{tab.component}
								</TabPanel>
							))}
						</TabsWrapper>
					</Wrapper>
				</BackdropContainer>
			</BackdropWrapper>
			<TitleSettings
				wrapperRef={wrapperRef}
				open={settingsOpen}
				data={title.data}
				handleClose={() => setSettingsOpen(false)}
				exportedFileName={
					titleSearchDisplayNameBuilder({ tokens: title.data?.readonly.titleSearchDisplayNameFullHTML }).title
				}
			/>
		</>
	);
}

type TabDefinition = { defaultUrlState: string; label: string; component: ReactNode; disabled?: boolean };

function addTabIfVisible(tab: TabDefinition, elementId: FeatureId): TabDefinition[] {
	return isFeatureEnabledForCurrentTenant(elementId) ? [tab] : [];
}
