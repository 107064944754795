import React, { useCallback, useEffect } from 'react';
import { PaginationNavigator, PaginatorProvider } from '@warehouse/shared/ui';
import { TitleSearchFilter } from '@warehouse/title/ui';
import { titleEditorStoreSelector, useTitleEditorStore, useTitleSearchQuery } from '@warehouse/title/domain';
import styled, { css } from 'styled-components';
import { NetworkStatus } from '@apollo/client';
import { addChildrenStoreSelector, useAddChildrenStore } from '@warehouse/title/feature-add-children';
import { TitleListActionsTopBar } from './title-list/TitleListActionsTopBar';
import { TitleList } from './title-list/TitleList';

export function MultiTitleSearch({
	licensor,
	defaultWorkTypeUuid = null,
	supportedWorkTypeUuids,
	placeholder,
	headerSlot,
}: MultiTitleSearchProps) {
	return (
		<PaginatorProvider>
			<MultipleTitleSearchCore
				licensor={licensor}
				defaultWorkTypeUuid={defaultWorkTypeUuid}
				supportedWorkTypeUuids={supportedWorkTypeUuids}
				placeholder={placeholder}
				headerSlot={headerSlot}
			/>
		</PaginatorProvider>
	);
}

function MultipleTitleSearchCore({
	licensor,
	defaultWorkTypeUuid = null,
	supportedWorkTypeUuids,
	placeholder,
	headerSlot,
}: MultiTitleSearchProps) {
	const searchValue = useAddChildrenStore(addChildrenStoreSelector.searchValue);
	const workTypeUuids = useAddChildrenStore(addChildrenStoreSelector.workTypeUuids);
	const licensorDisplayName = useAddChildrenStore(addChildrenStoreSelector.licensorDisplayName);
	const action = useAddChildrenStore(addChildrenStoreSelector.actions);
	const relationshipType = useAddChildrenStore(addChildrenStoreSelector.relationshipType);
	const isTitleHidden = useTitleEditorStore(titleEditorStoreSelector.isTitleHidden);

	// If the default work type uuid is defined, and workTypeUuids are not, set them to the default one
	useEffect(() => {
		if (defaultWorkTypeUuid && !workTypeUuids) {
			action.setWorkTypeUuids([defaultWorkTypeUuid]);
			action.setSelectedWorkTypeUuid(defaultWorkTypeUuid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultWorkTypeUuid]);

	const { data, networkStatus } = useTitleSearchQuery({
		searchCriteria: {
			searchValue,
			workTypeUuids,
			licensorDisplayName,
		},
		skip: !licensor,
		showHiddenTitles: isTitleHidden,
	});
	const titles = data?.searchTitlesV2?.documents || [];
	const hasPreviousData = !!data?.searchTitlesV2?.documents?.length;
	const isLoading = !hasPreviousData && networkStatus !== NetworkStatus.ready && networkStatus !== NetworkStatus.poll;

	const setLicensorFiltering = useCallback(() => {
		if (licensor !== licensorDisplayName && licensor) {
			action.setLicensorDisplayName(licensor);
		}
	}, [action, licensor, licensorDisplayName]);

	useEffect(() => {
		setLicensorFiltering();
	}, [licensor, licensorDisplayName, setLicensorFiltering]);

	return (
		<>
			<SearchWrapper>
				<HeaderWrapper>
					{headerSlot}
					<TitleSearchFilter
						allowedWorkTypeUuids={supportedWorkTypeUuids}
						placeholder={placeholder}
						disabled={relationshipType === null}
					/>
				</HeaderWrapper>
				<TitleListActionsTopBar titles={titles} />
			</SearchWrapper>
			<TitleList loading={isLoading} titles={titles} />
			<PaginationNavigator />
		</>
	);
}

interface MultiTitleSearchProps {
	defaultWorkTypeUuid?: string | null;
	licensor?: string;
	supportedWorkTypeUuids: string[];
	placeholder: string;
	headerSlot?: React.ReactNode;
}

const SearchWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: ${theme.spacing(2)};
	`,
);

const HeaderWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex: 1;
		gap: ${theme.spacing(2)};
	`,
);
