import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import TimeInput, {
	EIDRtoTime,
	IBMDurationFormatToSeconds,
	secondsToIBMDurationFormat,
} from '../../../../src/components/library/TimeInput';

export function TitleTimeInputField({
	ariaLabel,
	label,
	path,
	style,
	jumpToParentButtonDisabled,
}: TitleDatePickerFieldProps) {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalInherited<string>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	return (
		<TitleEditableField
			labelStyle={style}
			label={label}
			path={path}
			jumpToParentButtonDisabled={jumpToParentButtonDisabled}
			readComponent={
				<ReadOnlyTypography aria-label={ariaLabel} variant={getVariant(value)}>
					{EIDRtoTime(readOnlyValue?.displayValue)}
				</ReadOnlyTypography>
			}
			editComponent={
				<TimeInput
					disabledMargin
					fullWidth
					ariaLabel={ariaLabel}
					variant={getVariant(value)}
					value={IBMDurationFormatToSeconds(value?.displayValue)}
					onChange={(val) => {
						if (IBMDurationFormatToSeconds(value?.displayValue) !== val) {
							setValue((prev) => ({
								...prev,
								displayValue: secondsToIBMDurationFormat(val),
							}));
						}
					}}
					onBlur={() => commit()}
					height={38}
				/>
			}
		/>
	);
}

interface TitleDatePickerFieldProps {
	label: string;
	ariaLabel?: string;
	path: string;
	style?: React.CSSProperties;
	jumpToParentButtonDisabled?: boolean;
}

function getVariant(value: OptionalInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}
