import React, { useMemo } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';

interface LicensorFieldProps {
	title: Title | undefined;
}

const FakeDropdown = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(1)};
		cursor: not-allowed;
		display: flex;
		flex: 1;
		height: 36px;
		min-width: 0;
		padding: ${theme.spacing(0, 2)};
	`,
);
const FakeLabel = styled(Typography)(
	() => css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	`,
);

function LicensorField({ title }: LicensorFieldProps) {
	const licensorDisplayName = useMemo(() => {
		const associatedOrgs = getTitleData<AssociatedOrg[]>(title, fromBasic(['associatedOrgs', 'displayValue']));
		const licensorOrg = associatedOrgs?.find((org) => org.role === 'licensor');
		return licensorOrg?.displayName || '';
	}, [title]);

	return useMemo(
		() => (
			<TitleEditableField
				label="Licensor:"
				path="coreMetadata.basic.associatedOrgs"
				jumpToParentButtonDisabled
				readComponent={<ReadOnlyTypography aria-label="Licensor Field">{licensorDisplayName}</ReadOnlyTypography>}
				editComponent={
					<FakeDropdown aria-label="Licensor Field">
						<FakeLabel color="text.secondary" variant="buttonMediumRegular">
							{licensorDisplayName}
						</FakeLabel>
					</FakeDropdown>
				}
			/>
		),
		[title],
	);
}

export default LicensorField;
