import React, { useMemo } from 'react';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import NumberInput from '../../../library/NumberInput';

interface ReleaseYearFieldProps {
	releaseYear: string | undefined;
	setReleaseYear: (year: string | undefined) => void;
	isInherited: boolean;
}

function ReleaseYearField({ releaseYear, setReleaseYear, isInherited }: ReleaseYearFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Release Year:"
				path="coreMetadata.basic.releaseYear"
				readComponent={<ReadOnlyTypography>{releaseYear}</ReadOnlyTypography>}
				editComponent={
					<NumberInput
						disableRightPadding
						disableRightIcon
						fullWidth
						variant={isInherited ? 'prefilled' : 'default'}
						value={releaseYear || ''}
						onChange={(evt) => setReleaseYear(evt.target.value)}
						height={38}
					/>
				}
			/>
		),
		[isInherited, releaseYear],
	);
}

export default ReleaseYearField;
