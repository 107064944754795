import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { TitleFull } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import Series from './Series';
import Season from './Season';
import Supplemental from './Supplemental';
import Movie from './Movie';
import Performance from './Performance';
import Compilation from './Compilation';
import Episode from './Episode';
import useWorkTypes, { filterOutManifestation, Options } from '../../../../utils/hooks/titles/useWorkTypes';
import { LicensorField } from '../fields/Licensor';
import SequenceNumberField from '../fields/SequenceNumber';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import { StepOneTopRow, StepOneWrapper } from '../style';
import { Manifestation } from './Manifestation';

interface StepOneProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	watch: UseFormWatch<any>;
	isDryRunDirty: boolean;
	dryRanTitle: TitleFull | undefined;
	selectedParentTitle?: TitleFull;
	allowedRelationship?: string;
	parentTitleLabel?: string; // used only for getFilteredOptions
}

export function StepOne({
	control,
	setValue,
	errors,
	trigger,
	watch,
	isDryRunDirty,
	dryRanTitle,
	selectedParentTitle,
	allowedRelationship,
	parentTitleLabel,
}: StepOneProps) {
	const {
		getNameByUuid,
		getWorkTypesByRelationshipSource,
		getSupportedParentTypesByUuid,
		options: workTypesOptions,
	} = useWorkTypes();
	const workType = watch('titleType');
	const parentTitle = watch('parentTitle');
	const titleTypeTooltip = useTooltip('coreMetadata.basic.workType');

	const titleTypeOptions = useMemo(
		() =>
			allowedRelationship
				? getFilteredOptions(getWorkTypesByRelationshipSource(allowedRelationship), parentTitleLabel)
				: filterOutManifestation(workTypesOptions),
		[allowedRelationship, getWorkTypesByRelationshipSource, parentTitleLabel, workTypesOptions],
	);
	const titleTypeField = useMemo(
		() => (
			<Controller
				control={control}
				name="titleType"
				render={({ field }) => (
					<DropdownV2
						withSearch
						ariaLabel="Title Type Selector"
						tooltip={titleTypeTooltip?.tooltip}
						readOnly={titleTypeOptions.length === 1}
						options={titleTypeOptions}
						error={errors?.titleType?.message as string}
						value={field.value}
						onChange={async (e) => {
							// If we have a given parentTitle we use the value derived from the parent title itself
							if (!parentTitle)
								setValue('parentTitle.parentType', getSupportedParentTypesByUuid(e as string)[0]?.value);

							setValue('titleType', e as string);
							// If the selected work type is different from the previous one, reset the work type detail
							if (field.value !== e) {
								setValue('workTypeDetail', undefined);
							}
							await trigger('titleType');
						}}
						label={getLabelWithRequired('Title Type', true)}
					/>
				)}
			/>
		),
		[
			control,
			titleTypeTooltip?.tooltip,
			titleTypeOptions,
			errors?.titleType?.message,
			parentTitle,
			setValue,
			getSupportedParentTypesByUuid,
			trigger,
		],
	);

	const licensorField = useMemo(
		() => (
			<LicensorField
				control={control}
				setValue={setValue}
				watch={watch}
				selectedParentTitle={selectedParentTitle}
				parentHasBeenSelected={Object.keys(parentTitle?.title || {}).length !== 0}
				trigger={trigger}
				errors={errors}
				dryRanTitle={dryRanTitle}
				isRequired
			/>
		),
		[control, setValue, selectedParentTitle, parentTitle?.title, watch, trigger, errors, dryRanTitle],
	);

	const props = {
		workType,
		control,
		setValue,
		trigger,
		isDryRunDirty,
		errors,
		dryRanTitle,
		watch,
		parentTitle,
		selectedParentTitle,
	};

	const fieldsByTitleType = {
		Movie: <Movie {...props} />,
		Compilation: <Compilation {...props} />,
		Season: <Season {...props} />,
		Series: <Series {...props} />,
		Episode: <Episode {...props} />,
		Supplemental: <Supplemental {...props} />,
		Edit: <Performance {...props} />,
		Manifestation: <Manifestation {...props} />,
	};

	const titleTypeName = getNameByUuid(workType);
	const fieldsToDisplay = titleTypeName ? fieldsByTitleType[titleTypeName as keyof typeof fieldsByTitleType] : null;

	return (
		<StepOneWrapper>
			<StepOneTopRow>
				{licensorField}
				{titleTypeField}
				{isSequenceNumberFieldDisplayed(titleTypeName) && (
					<SequenceNumberField
						control={control}
						setValue={setValue}
						isRequired
						trigger={trigger}
						errors={errors}
						dryRanTitle={dryRanTitle}
					/>
				)}
			</StepOneTopRow>
			{fieldsToDisplay}
		</StepOneWrapper>
	);
}

function isSequenceNumberFieldDisplayed(titleType: string | undefined) {
	return titleType === 'Season' || titleType === 'Episode';
}

// getFilteredOptions is hardcoded to remove the manifestation when the Tab is called "Edit"
function getFilteredOptions(options: Options, parentTitleLabel?: string) {
	if (parentTitleLabel === 'Edit') {
		return filterOutManifestation(options);
	}

	return options;
}
