import React, { useMemo } from 'react';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import TextInput from '../../../library/TextInput';

interface DistributionNumberFieldProps {
	distributionNumber: string;
	setDistributionNumber: (distributionNumber: string) => void;
	isInherited: boolean;
	onBlur: () => void;
}

function DistributionNumberField({ distributionNumber, setDistributionNumber, onBlur }: DistributionNumberFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Distribution Number:"
				path="coreMetadata.basic.sequenceInfo.distributionNumber.distributionNumber"
				jumpToParentButtonDisabled
				readComponent={<ReadOnlyTypography>{distributionNumber}</ReadOnlyTypography>}
				editComponent={
					<TextInput
						fullWidth
						width="50%"
						value={distributionNumber}
						onChange={(e) => setDistributionNumber(e.target.value)}
						onBlur={onBlur}
						height={38}
					/>
				}
			/>
		),
		[distributionNumber],
	);
}

export default DistributionNumberField;
