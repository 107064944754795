import React, { useMemo } from 'react';
import TextInput from '../../../library/TextInput';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';

interface PlatformSpecificationFieldProps {
	platformSpecification: string;
	setPlatformSpecification: (platformSpecification: string) => void;
	isInherited: boolean;
}

function PlatformSpecificationField({
	platformSpecification,
	setPlatformSpecification,
	isInherited,
}: PlatformSpecificationFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Platform Specification:"
				readComponent={<ReadOnlyTypography>Platform Specification</ReadOnlyTypography>}
				editComponent={
					<TextInput
						height={38}
						fullWidth
						disableRightPadding
						value={platformSpecification}
						onChange={(e) => setPlatformSpecification(e.target.value)}
					/>
				}
			/>
		),
		[isInherited, platformSpecification],
	);
}

export default PlatformSpecificationField;
