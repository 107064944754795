import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARIES
import CountriesOfOriginField from '../fields/CountriesOfOrigin';
import ReleaseDate from '../fields/ReleaseDate';
import OriginalLanguagesField from '../fields/OriginalLanguages';
import RunLengthField from '../fields/RunLength';
import SortTitleField from '../fields/SortTitle';

// STYLES
import { Row } from './styles';
import MadeForRegionField from '../fields/MadeForRegion';

interface SeasonProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	dryRanTitle?: Title | undefined;
}

function Season({ control, errors, setValue, trigger, dryRanTitle }: SeasonProps) {
	return (
		<div>
			<Row>
				<CountriesOfOriginField
					control={control}
					setValue={setValue}
					trigger={trigger}
					isRequired
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
				<ReleaseDate
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
					isRequired
				/>
			</Row>
			<Row>
				<SortTitleField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
				<RunLengthField
					control={control}
					setValue={setValue}
					isRequired
					trigger={trigger}
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
			</Row>
			<Row>
				<MadeForRegionField
					control={control}
					setValue={setValue}
					trigger={trigger}
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
			</Row>
			<OriginalLanguagesField
				control={control}
				setValue={setValue}
				trigger={trigger}
				errors={errors}
				isRequired
				dryRanTitle={dryRanTitle}
			/>
		</div>
	);
}

export default Season;
