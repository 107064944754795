import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { FrameRate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { titlePaths, WorkType, isInherited, OptionalNullInherited } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';
import frameRateJson from '../../../../src/assets/json-administration-profiles/availableFrameRate.json';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave, {
	optionalNullInheritedToOptionalInherited,
} from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import UseMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import { useUpdateValue } from '../../domain/useUpdateValue';

const framerates = frameRateJson.map((fr) => ({
	label: fr.label,
	value: fr.name.toString(),
}));

export function FrameRateField() {
	const {
		readOnlyValue: readonlyFrameRate,
		setValue: setFrameRate,
		value: frameRate,
		commit,
	} = useTitleAutoSave<OptionalInherited<FrameRate | null>>({
		path: UseMemoJsonPath(titlePaths.frameRate.split('.')),
		label: 'Frame Rate:',
	});

	const currentTitle = useTitleEditorStore(titleEditorStoreSelector.title);

	const frameRateFrameRate = useMemo(() => frameRate?.displayValue?.frameRate.toString(), [frameRate]);

	const frameRateVariant = getVariant(frameRate);

	const { updateValue } = useUpdateValue<FrameRate | null>({ value: frameRate });

	return (
		<TitleEditableField
			label="Frame Rate:"
			path={titlePaths.frameRate}
			jumpToParentButtonDisabled
			height={48}
			readComponent={
				<ReadOnlyTypography variant={frameRateVariant}>
					{readonlyFrameRate?.displayValue?.frameRate?.toString()}
				</ReadOnlyTypography>
			}
			editComponent={
				<div style={{ width: '100%' }}>
					<DropdownV2
						options={framerates}
						variant={frameRateVariant}
						value={frameRateFrameRate}
						bottomActions={
							<BottomActionClear
								action={() => {
									setFrameRate(updateValue(null));
									commit?.();
								}}
							/>
						}
						onChange={(value) => {
							setFrameRate((prev) => ({
								...prev,
								displayValue: computeNextFrameRate(value, frameRate, currentTitle.readonly.workType),
							}));
						}}
						height={38}
						onBlur={commit}
					/>
				</div>
			}
		/>
	);
}

function computeNextFrameRate(
	value: string | null,
	currentFrameRate: OptionalInherited<FrameRate | null> | undefined,
	workType: WorkType,
): FrameRate | null {
	const parsedV = value ? parseInt(value, 10) : null;

	let frameRateData: FrameRate | null;

	if (parsedV && !Number.isNaN(parsedV)) {
		if (typeof currentFrameRate?.displayValue?.original === 'boolean') {
			frameRateData = {
				frameRate: parsedV,
				original: currentFrameRate.displayValue.original,
			};
		} else {
			frameRateData = {
				frameRate: parsedV,
				original: ![WorkType.Manifestation, WorkType.Edit].includes(workType),
			};
		}
	} else {
		frameRateData = null;
	}

	return frameRateData;
}

function getVariant(value: OptionalNullInherited<unknown> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<unknown>({ inheritedObject: optionalNullInheritedToOptionalInherited(value) }).isInherited
		? 'prefilled'
		: 'default';
}
