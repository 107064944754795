import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

// Components
import { IndexedTitleV2 } from '@warehouse/graphql';
import { ErrorAlert, ErrorsAlert } from '@warehouse/shared/ui';
import { useTitle } from '@warehouse/title/domain';
import { createTitleInputToInfraInput } from '@warehouse/title/infra';
import { ButtonLoadingStep } from '../../../components/library/ButtonLoadingStep';
import { StepOne } from './stepOne/stepOne';
import StepTwo from './stepTwo/stepTwo';

// Utils

// Library
import Dialog from '../../../components/library/Dialog';
import DialogTitle, { CloseButton } from '../../../components/library/DialogTitle';
import Button from '../../../components/library/Button';
import StepLabel from '../../../components/library/StepLabel';
import Loader from '../../../components/library/Loader';
import ScrollableDialogContent from '../../../components/library/ScrollableDialogContent';
import ConfirmationModal from '../../../components/library/ConfirmationModal';
import schema from './schema';
import useDryRun from '../useDryRun';
import fieldsToValidateByTitleTypeStepOne from './stepOne/fieldsToValidateByTitleType';
import fieldsToValidateByTitleTypeStepTwo from './stepTwo/fieldsToValidateByTitleType';
import useCreateTitleV2 from '../../../utils/hooks/titles/useCreateTitleV2';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import deepEquality from '../../../utils/deepEquality';
import { fromBasic, getTitleData } from '../../../utils/titleGetProperty';
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { LoaderWrapper } from '../../../components/library/LoaderWrapper';

// STYLE
import { ActionWrapper, ContentWrapper, CreateTitleForm, StepperWrapper, StepWrapper } from './style';

// JSON
import { ChildrenTabNameOutput } from '../../title/hooks/useChildrenTabName';

interface CreateTitleModalProps {
	open: boolean;
	handleClose: () => void;
	handleCancel?: () => void;
	selectedParentUuid?: string;
	closeOnCreate?: boolean;
	onCreated?: (titleUuid: string) => void;
	parentTitleProperties?: ChildrenTabNameOutput;
}

function workTypeHasAutoGeneratedTitle(workTypeName: string | undefined) {
	return workTypeName === 'Episode' || workTypeName === 'Season';
}

function getFieldsToValidateStepOne(
	workTypeName: string | undefined,
	relationshipType: string,
	parentTitle: IndexedTitleV2 | undefined,
	sequenceNumber: string | undefined,
) {
	const fieldsForCurrentWorkType =
		fieldsToValidateByTitleTypeStepOne[workTypeName as keyof typeof fieldsToValidateByTitleTypeStepOne];

	if (workTypeName === 'Supplemental') {
		if (relationshipType) return [...fieldsForCurrentWorkType, 'parentTitle'];
		if (parentTitle) return [...fieldsForCurrentWorkType, 'relationshipType'];
		return fieldsForCurrentWorkType;
	}

	if (workTypeHasAutoGeneratedTitle(workTypeName)) {
		if (sequenceNumber === undefined || sequenceNumber === '') return [...fieldsForCurrentWorkType, 'title'];
		return fieldsForCurrentWorkType;
	}

	return workTypeName ? fieldsForCurrentWorkType : ['titleType'];
}

export function CreateTitleModal({
	open,
	handleClose,
	handleCancel,
	selectedParentUuid,
	closeOnCreate = false,
	onCreated,
	parentTitleProperties,
}: CreateTitleModalProps) {
	const { getNameByUuid } = useWorkTypes();
	const { data: selectedParentTitle, isPending: parentTitleLoading } = useTitle({
		skipable: true,
		uuid: selectedParentUuid,
	});
	const defaultParentWorkType = useMemo(
		() => (selectedParentTitle?.uuid ? getTitleData<string>(selectedParentTitle, fromBasic(['workType'])) : 'all'),
		[selectedParentTitle],
	);
	const defaultWorkType = parentTitleProperties ? parentTitleProperties.uuid : undefined;
	const defaultWorkTypeDetail = useMemo(
		() =>
			defaultWorkType
				? availableWorkTypeDetailsByWorkType[
						getNameByUuid(defaultWorkType) as keyof typeof availableWorkTypeDetailsByWorkType
				  ]?.defaultWorkTypeDetail[0]?.value ?? ''
				: '',
		[defaultWorkType],
	);
	const defaultLicensor = useMemo(() => {
		if (!selectedParentTitle?.uuid) return '';
		const associatedOrgs = getTitleData<AssociatedOrg[]>(
			selectedParentTitle,
			fromBasic(['associatedOrgs', 'displayValue']),
		);
		return associatedOrgs?.find((org) => org.role === 'licensor')?.displayName ?? '';
	}, [selectedParentTitle]);
	const defaultParentTitle = useMemo(() => selectedParentTitle?.readonly || {}, [selectedParentTitle]);
	const defaultRelationshipType = parentTitleProperties?.relationshipType ?? '';

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		trigger,
		watch,
		getValues,
		reset,
	} = useForm<any>({
		resolver: ajvResolver(schema),
		defaultValues: {
			titleType: undefined,
			isCollection: false,
			parentTitle: {
				parentType: defaultParentWorkType,
				title: defaultParentTitle,
			},
			houseSequence: '',
			licensor: defaultLicensor,
			sortTitle: '',
			title: '',
			titleLanguage: '',
			countriesOfOrigin: [],
			collectionType: '',
			workTypeDetail: defaultWorkTypeDetail,
			releaseDate: undefined,
			approximateLength: 0,
			distributionNumber: '',
			sequenceNumber: '',
			compilationClass: '',
			relationshipType: defaultRelationshipType,
			originalLanguages: [],
			versionLanguages: [],

			// Performance
			editUse: '',
			madeForRegions: {
				madeForRegions: [],
				isInclude: true,
			},
			editClass: [],
		},
	});

	const [graphQLError, setGraphQLError] = useState<any>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<'cancel' | 'close' | false>(false);

	const theme = useTheme();

	const [activeStep, setActiveStep] = useState(0);
	const [titleUuidCreated, setTitleUuidCreated] = useState<string>('');
	const [refetchIntervalId, setRefetchIntervalId] = useState<NodeJS.Timer>();
	const [refetchIntervalCount, setRefetchIntervalCount] = useState<number>(0);
	const navigate = useNavigate();
	const workType = watch('titleType');
	const workTypeDetail = watch('workTypeDetail');
	const relationshipType = watch('relationshipType');
	const sequenceNumber = watch('sequenceNumber');
	const watchedData = watch();
	const [watchedDataState, setWatchedDataState] = useState<any>(() => structuredClone(watchedData));
	const [createTitleV2] = useCreateTitleV2();

	const workTypeName = getNameByUuid(workType);

	useEffect(() => {
		if (!workTypeDetail) {
			const workTypeDetailsElement =
				availableWorkTypeDetailsByWorkType[workTypeName as keyof typeof availableWorkTypeDetailsByWorkType];
			const defaultWorkTypeDetails = workTypeDetailsElement?.defaultWorkTypeDetail[0]?.value;

			if (defaultWorkTypeDetails) {
				setValue('workTypeDetail', defaultWorkTypeDetails);
			}
		}
	}, [workType]);

	const memoizedPayload = useMemo(
		() => createTitleInputToInfraInput({ data: watchedDataState, workType })?.metadata,
		[watchedDataState, workType],
	);

	useEffect(() => {
		if (deepEquality(watchedData, watchedDataState)) return;
		setWatchedDataState(structuredClone(watchedData));
	}, [watchedData]);

	const {
		dryRanTitle,
		setErrors: setGraphQLErrors,
		errors: graphQLErrors,
		isDirty: isDryRunDirty,
	} = useDryRun({
		payload: memoizedPayload,
		skip: !open,
	});

	useEffect(() => {
		if (!open) {
			reset();
			setActiveStep(0);
			setGraphQLErrors([]);
		}
	}, [open]);

	// When the parent title or the open modal state changes, reset the title type to the default
	useEffect(() => {
		if (parentTitleProperties?.uuid) {
			setValue('titleType', parentTitleProperties.uuid);
		}
	}, [parentTitleProperties, open]);

	const steps = [
		{
			component: (
				<StepOne
					trigger={trigger}
					control={control}
					setValue={setValue}
					errors={errors}
					watch={watch}
					isDryRunDirty={isDryRunDirty}
					dryRanTitle={dryRanTitle}
					selectedParentTitle={selectedParentTitle}
					allowedRelationship={parentTitleProperties?.relationshipType}
					parentTitleLabel={parentTitleProperties?.singularLabel}
				/>
			),
			label: 'Title Settings',
			fieldsToValidate: getFieldsToValidateStepOne(
				workTypeName,
				relationshipType,
				watchedData?.parentTitle?.title,
				sequenceNumber,
			),
		},
		{
			component: (
				<StepTwo
					trigger={trigger}
					control={control}
					dryRanTitle={dryRanTitle}
					setValue={setValue}
					errors={errors}
					workType={workType}
				/>
			),
			label: 'Title Fields',
			fieldsToValidate: workTypeName
				? fieldsToValidateByTitleTypeStepTwo(dryRanTitle)[
						workTypeName as keyof typeof fieldsToValidateByTitleTypeStepTwo
				  ]
				: ['titleType'],
		},
	];

	const resetInterval = () => {
		clearInterval(refetchIntervalId);
		setRefetchIntervalId(undefined);
		setRefetchIntervalCount(0);
	};

	useEffect(() => {
		if (titleUuidCreated && selectedParentUuid) {
			setRefetchIntervalId(
				setInterval(async () => {
					if (refetchIntervalCount === 10) {
						resetInterval();
					} else {
						setRefetchIntervalCount((prev) => prev + 1);
					}
				}, 5000),
			);
		}

		return () => {
			if (refetchIntervalId) clearInterval(refetchIntervalId);
		};
	}, [titleUuidCreated]);

	const computeTitleName = (data: any) => {
		if (getNameByUuid(workType) === 'Episode' && sequenceNumber !== '' && data?.title === '') {
			return `Episode ${parseInt(sequenceNumber, 10)}`;
		}
		if (getNameByUuid(workType) === 'Season' && sequenceNumber !== '' && data?.title === '') {
			return `Season ${parseInt(sequenceNumber, 10)}`;
		}
		return data.title;
	};

	const onAdd = async (data: any) => {
		try {
			setLoading(true);
			if (!workType) return;

			const payload = createTitleInputToInfraInput({
				data: {
					...data,
					title: computeTitleName(data),
				},
				workType,
				forcedRelationshipType: parentTitleProperties?.relationshipType,
			});

			if (!payload) {
				console.error('Error creating title payload');
			}

			if (!payload) return;
			const result = await createTitleV2({
				variables: {
					input: payload,
				},
			});

			const titleUuid = result.data?.createTitleV2?.uuid;
			setTitleUuidCreated(titleUuid || '');

			if (titleUuid && !closeOnCreate) {
				navigate(`/title/${titleUuid}`);
			} else {
				handleClose();
				if (onCreated && titleUuid) onCreated(titleUuid);
			}
		} catch (e: any) {
			setGraphQLError(e?.graphQLErrors?.[0] || undefined);
		} finally {
			setLoading(false);
		}
	};

	const hasTheUserEnteredData = () => workType !== defaultWorkType || activeStep !== 0;

	const onNextStep = async () => {
		const { fieldsToValidate } = steps[activeStep];

		if (!fieldsToValidate) {
			setActiveStep((lastStep) => lastStep + 1);
			return;
		}

		const validated = await trigger(fieldsToValidate);

		if (validated) {
			if (activeStep === steps.length - 1) {
				await onAdd(getValues());
			} else {
				setActiveStep((lastStep) => lastStep + 1);
			}
		}
	};

	const onPrevStep = () => {
		if (activeStep === 0) {
			if (hasTheUserEnteredData()) {
				setConfirmModalIsOpen('cancel');
			} else if (handleCancel) {
				handleCancel();
			} else {
				handleClose();
			}
		} else {
			setActiveStep((lastStep) => lastStep - 1);
		}
	};

	const onCloseRequest = () => {
		if (hasTheUserEnteredData()) {
			setConfirmModalIsOpen('close');
		} else {
			handleClose();
		}
	};

	return (
		<>
			<Dialog open={open} fullWidth maxWidth="lg" onClose={onCloseRequest}>
				<DialogTitle>
					New Title
					<CloseButton onClose={onCloseRequest} />
				</DialogTitle>
				<ScrollableDialogContent>
					<CreateTitleForm onSubmit={handleSubmit(onAdd)}>
						<ContentWrapper>
							<StepperWrapper>
								<Stepper activeStep={activeStep}>
									{steps.map((step, index) => (
										<Step key={`${step.label} ${index}`}>
											<StepLabel>{step.label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</StepperWrapper>
							<StepWrapper>
								{graphQLErrors?.length > 0 && (
									<ErrorsAlert
										onClose={() => setGraphQLErrors([])}
										errorMessages={graphQLErrors.map((err: any) => err.message)}
									/>
								)}
								{graphQLError !== undefined && (
									<ErrorAlert errorMessage={graphQLError.message} onClose={() => setGraphQLError(undefined)} />
								)}
								<LoaderWrapper loading={parentTitleLoading}>{steps[activeStep]?.component}</LoaderWrapper>
							</StepWrapper>
						</ContentWrapper>
						<ActionWrapper>
							<Button $fullHeight onClick={onPrevStep}>
								{activeStep === 0 ? 'Cancel' : 'Back'}
							</Button>
							<ButtonLoadingStep
								disabled={!workType}
								loading={isDryRunDirty && activeStep === 0}
								$fullHeight
								onClick={onNextStep}
								nxstyle="primary-blue"
							>
								{loading ? (
									<Loader size={20} color={theme.palette.light.main} />
								) : (
									<span>{activeStep === steps.length - 1 ? 'Submit' : 'Next'}</span>
								)}
							</ButtonLoadingStep>
						</ActionWrapper>
					</CreateTitleForm>
				</ScrollableDialogContent>
			</Dialog>
			<ConfirmationModal
				open={!!confirmModalIsOpen}
				title="Are you sure?"
				message="You have unsaved changes. Are you sure you want to leave?"
				onConfirm={() => {
					setConfirmModalIsOpen(false);
					if (confirmModalIsOpen === 'close' || !handleCancel) {
						handleClose();
						setGraphQLErrors([]);
						setGraphQLError(undefined);
					} else {
						handleCancel();
					}
				}}
				onClose={() => {
					setConfirmModalIsOpen(false);
				}}
			/>
		</>
	);
}
