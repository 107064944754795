import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, useWatch } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARY
import { TerritoriesSelector } from '../../../../components/titles/TerritoriesSelector';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import IncludeExcludeBottomAction from '../../../../components/titles/IncludeExcludeBottomAction';

interface MadeForRegionFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function MadeForRegionField({ control, errors, setValue, trigger, isRequired = false }: MadeForRegionFieldProps) {
	const madeForRegionsTooltip = useTooltip('coreMetadata.basic.versionIntent.madeForRegions');

	const madeForRegionsValue = useWatch({
		control,
		name: 'madeForRegions',
	});

	return useMemo(
		() => (
			<Controller
				name="madeForRegions"
				control={control}
				render={({ field }) => (
					<TerritoriesSelector<string[]>
						tooltip={madeForRegionsTooltip?.tooltip}
						label={getLabelWithRequired(`${!field.value.isInclude ? 'Exclude ' : ''}Made For Regions`, isRequired)}
						value={field.value.madeForRegions}
						multiple
						bottomActions={
							<IncludeExcludeBottomAction
								value={field.value.isInclude ? 'includes' : 'excludes'}
								defaultValue="includes"
								onChange={(e) => {
									setValue('madeForRegions.isInclude', e.target.value === 'includes');
								}}
							/>
						}
						onSelect={async (value) => {
							setValue('madeForRegions.madeForRegions', [...field.value.madeForRegions, value]);
							if (isRequired) await trigger('madeForRegions.madeForRegions');
						}}
						onDeselect={async (value) => {
							setValue(
								'madeForRegions.madeForRegions',
								field.value.madeForRegions.filter((item: any) => item !== value),
							);
							if (isRequired) await trigger('madeForRegions.madeForRegions');
						}}
						error={errors?.countriesOfOrigin?.message as string}
						getPopupContainer={() => document.body}
					/>
				)}
			/>
		),
		// We need to add the value "madeForRegionsValue" even if the value is not used in the component,
		// because as the controller manages an object, the component doesn't refresh when the value changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			control,
			madeForRegionsValue,
			errors?.countriesOfOrigin?.message,
			isRequired,
			madeForRegionsTooltip?.tooltip,
			setValue,
			trigger,
		],
	);
}

export default MadeForRegionField;
