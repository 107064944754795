import React from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleTimeInputField } from '../../ui/fields/TitleTimeInputField';

export function RunLengthField() {
	return (
		<TitleTimeInputField
			path={titlePaths.runLength}
			label="Run Length:"
			ariaLabel="Run Length Field"
			jumpToParentButtonDisabled
		/>
	);
}
