import React, { createContext, useEffect, ReactNode, useRef, useState, useMemo, useCallback } from 'react';
import { Subject, concatMap, of } from 'rxjs';

// UTILS
import { useSnackbar } from 'notistack';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { AddedRecord } from '@warehouse/graphql';
import { TitleFull } from '@warehouse/title/core';
import { JsonPointer } from '../../../utils/getDeepProperty';
import handleClickableErrors from '../../../utils/handleClickableErrors';
import { useAddRecord } from '../../../utils/hooks/titles/useAddRecord';
import { useRemoveRecord } from '../../../utils/hooks/titles/useRemoveRecord';
import { getTitleData } from '../../../utils/titleGetProperty';

export type OnErrorFn = (error: any) => void;

export type CommonEditObject = {
	path: JsonPointer;
	titleUuid: string | undefined;
	label: string;
	onStart?: () => void;
	onError?: OnErrorFn;
	signal?: AbortSignal;
};

type EditsTitleEditObject<T> = {
	mutation: 'edits';
	edits: {
		value: T;
		path: JsonPointer;
	}[];
	type: string;
	onSuccess: () => void;
} & Omit<CommonEditObject, 'path'>;

export type EditTitleEditObject<T> = {
	mutation: 'edit';
	value: T;
	type: string;
	onSuccess: OnEditSuccessFn;
} & CommonEditObject;

type RemoveRecordEditObject = {
	mutation: 'remove';
	recordUuids: string[];
	onSuccess: OnRemoveSuccessFn;
} & CommonEditObject;

export type OnAddSuccessFn = (record: unknown, recordUuid: string) => void;
export type OnEditSuccessFn = (title: TitleFull) => void;
export type OnRemoveSuccessFn = (removedUuids: string[]) => void;

type AddRecordEditObject<T> = {
	mutation: 'add';
	value: T;
	onSuccess: OnAddSuccessFn;
} & CommonEditObject;

export type EditObject<T> =
	| AddRecordEditObject<T>
	| EditTitleEditObject<T>
	| EditsTitleEditObject<T>
	| RemoveRecordEditObject;

function withoutInheritance(value: any) {
	if (value && typeof value === 'object' && 'displayValue' in value) {
		return value.displayValue;
	}

	return value;
}

interface TitleAutoSaveQueueContextOutput {
	loading: boolean;
	queueLength: number;
	addToQueue: (editObject: EditObject<any>) => void;
}

export const TitleAutoSaveQueueContext = createContext<TitleAutoSaveQueueContextOutput>({
	loading: false,
	queueLength: 0,
	addToQueue: () => {},
});

function TitleAutoSaveQueueContextProvider({ children }: { children: ReactNode }) {
	const { enqueueSnackbar } = useSnackbar();
	const [queueLength, setQueueLength] = useState<number>(0);
	const queueSubject = useRef(new Subject<EditObject<any>>());
	const editTitleMutation = useTitleEditorStore(titleEditorStoreSelector.actions).editTitle;
	const [editTitleIsPending, setEditTitleIsPending] = useState(false);
	const [addRecord, { loading: addRecordLoading }] = useAddRecord();
	const [removeRecord, { loading: removeRecordLoading }] = useRemoveRecord();

	const makeEditsTitleMutation = async (editObject: EditsTitleEditObject<any>) => {
		if (!editObject.titleUuid) return;
		setEditTitleIsPending(true);
		await editTitleMutation(
			{
				edits: editObject.edits.map((edit) => ({
					path: edit.path.slice(1).join('.'),
					value: JSON.stringify(withoutInheritance(edit.value)),
				})),
				uuid: editObject.titleUuid,
				type: editObject.type,
			},
			{
				signal: editObject.signal,
			},
		);
		setEditTitleIsPending(false);
		editObject.onSuccess();
	};

	const makeEditTitleMutation = async (editObject: EditTitleEditObject<any>) => {
		if (!editObject.titleUuid) return;
		setEditTitleIsPending(true);
		const result = await editTitleMutation(
			{
				edits: [
					{
						path: editObject.path.slice(1).join('.'),
						value: JSON.stringify(withoutInheritance(editObject.value)),
					},
				],
				uuid: editObject.titleUuid,
				type: editObject.type,
			},
			{
				signal: editObject.signal,
			},
		);
		setEditTitleIsPending(false);

		editObject.onSuccess(result);
	};

	const makeRemoveRecordMutation = async (removeRecordObject: RemoveRecordEditObject) => {
		await removeRecord({
			variables: {
				input: {
					path: removeRecordObject.path.slice(1).join('.'),
					titleUuid: removeRecordObject.titleUuid || '',
					recordUuids: removeRecordObject.recordUuids,
				},
			},
			context: {
				onSuccess: () => removeRecordObject.onSuccess(removeRecordObject.recordUuids),
				...(removeRecordObject.signal ? { fetchOptions: { signal: removeRecordObject.signal } } : {}),
			},
		});
	};

	const makeAddRecordMutation = async (addRecordObject: AddRecordEditObject<any>) => {
		await addRecord({
			variables: {
				input: {
					path: addRecordObject.path.slice(1).join('.'),
					uuid: addRecordObject.titleUuid || '',
					value: JSON.stringify(withoutInheritance(addRecordObject.value)),
				},
			},
			context: {
				onSuccess: (addedRecord: AddedRecord) => {
					addRecordObject.onSuccess(
						getTitleData(JSON.parse(addedRecord.title.state), [...addRecordObject.path, addedRecord.recordUuid]),
						addedRecord.recordUuid,
					);
				},
				...(addRecordObject.signal ? { fetchOptions: { signal: addRecordObject.signal } } : {}),
			},
		});
	};

	const makeMutation = async (editObject: EditObject<any>) => {
		try {
			editObject.onStart?.();

			if (editObject.mutation === 'edit') await makeEditTitleMutation(editObject);
			else if (editObject.mutation === 'edits') await makeEditsTitleMutation(editObject);
			else if (editObject.mutation === 'add') await makeAddRecordMutation(editObject);
			else if (editObject.mutation === 'remove') await makeRemoveRecordMutation(editObject);
			enqueueSnackbar(`${editObject.label.replace(':', '')} has been updated`, {
				variant: 'success',
			});
		} catch (error: any) {
			if (editObject.signal?.aborted) return of(0);
			editObject?.onError?.(error);
			handleClickableErrors(
				enqueueSnackbar,
				`${editObject.label.replace(':', '')} : ${error.message}`,
				error?.graphQLErrors?.[0]?.errorInfo?.titleUuid,
			);
		} finally {
			setQueueLength((prev) => prev - 1);
		}
		return of(0);
	};

	useEffect(() => {
		const subscription = queueSubject.current.pipe(concatMap(makeMutation)).subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const addToQueue = useCallback(
		(editObject: EditObject<any>) => {
			setQueueLength((prev) => prev + 1);
			queueSubject.current.next(editObject);
		},
		[queueSubject.current, setQueueLength],
	);

	const valueToProvide = useMemo(
		() => ({
			loading: editTitleIsPending || removeRecordLoading || addRecordLoading,
			queueLength,
			addToQueue,
		}),
		[editTitleIsPending || removeRecordLoading || addRecordLoading, queueLength, addToQueue],
	);

	return <TitleAutoSaveQueueContext.Provider value={valueToProvide}>{children}</TitleAutoSaveQueueContext.Provider>;
}

export default TitleAutoSaveQueueContextProvider;
