import React, {
	ReactElement,
	KeyboardEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
	FC,
	RefObject,
	ReactNode,
} from 'react';
import classNames from 'classnames';
import { Select, SelectProps, Tag } from 'antd';
import { BaseOptionType, DefaultOptionType, RefSelectProps } from 'antd/es/select';
import { alpha, IconButton, Tooltip, Typography } from '@mui/material';
import { mdiClose, mdiInformation, mdiMagnify } from '@mdi/js';
import styled, { createGlobalStyle, css } from 'styled-components';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Icon } from '@mdi/react';

import { TooltipIcon } from '@warehouse/shared/legacy-ui';
import { Label, LabelBlockWrapper, SearchInput, SearchWrapper } from '../../../../src/components/library/DropdownUtils';
import Checkbox from '../../../../src/components/library/Checkbox';
import Loader from '../../../../src/components/library/Loader';
import { DropdownV2Props, DisplayValueType } from './types';
import { PlaceholderChip } from '../../../../src/components/library/SelectDropdown/styles';
import { useTopBottomObserver } from './useTopBottomObserver';
import InputError, { WrapperInputError } from '../../../../src/components/library/InputError';
import { ReadOnlyField } from '../../../../src/components/library/ReadOnlyField';

function numberToPx(value: number): string {
	return `${value}px`;
}

export function DropdownV2<
	ValueType extends string | string[] | boolean | undefined,
	OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
	value,
	options,
	mode,
	label,
	labelIcon,
	error,
	tooltip,
	defaultValue,
	preventEventPropagation,
	loading = false,
	readOnly = false,
	withSearch = true,
	width,
	onNewOptionAdded,
	inlineLabel,
	pickList = false,
	searchInputType = 'text',
	searchPlaceholder,
	placeholder,
	disabledMargin = false,
	forceOpen = false,
	transparentBorder,
	style,
	variant = 'default',
	bottomActions,
	optionFilterProp = 'label',
	onDropdownVisibleChange,
	onSelect,
	onChange,
	onTagRemoved,
	chipHeight = 24,
	listItemHeight = 48,
	height = 48,
	backgroundColor,
	onBlur,
	forbiddenPickListValues,
	ariaLabel,
	...props
}: DropdownV2Props<ValueType, OptionType>) {
	if (pickList) {
		// eslint-disable-next-line no-param-reassign
		withSearch = true;
	}
	const [open, setOpen] = useState<boolean>(false);
	const [mirroredValue, setMirroredValue] = useState<ValueType | null | undefined>(value ?? defaultValue);
	const [searchValue, setSearchValue] = useState<string>('');
	const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);

	const antdSelectRef = useRef<RefSelectProps>(null);
	const searchInputRef = useRef<HTMLInputElement>(null);

	const placement = useTopBottomObserver(dropdownRef);

	const maxTagPlaceholder = (omittedValues: DisplayValueType[]) => {
		if (!Array.isArray(value) || !omittedValues.length) return null;

		const overflowIndicator = (overflownValues: DisplayValueType[]) => (
			<Tooltip title={overflownValues.map((option) => option.label).join(', ')}>
				<StyledTag $chipHeight={chipHeight} $overflowChip>
					+{overflownValues.length}
				</StyledTag>
			</Tooltip>
		);

		if (omittedValues.length === value.length)
			return (
				<>
					<StyledTag $chipHeight={chipHeight}>{omittedValues[0].label}</StyledTag>
					{omittedValues.length > 1 && overflowIndicator(omittedValues.slice(1))}
				</>
			);
		return overflowIndicator(omittedValues);
	};

	const tagRender: TagRender = (customTagProps) => {
		/**
		 *
		 * It looks like antd introduced breaking changes in a version, or something like that.
		 *
		 * Example: ["France", "United States", "Spain"] -> dropdown has a certain width that makes only "France" and "United States" visible
		 * "tagRender" function should be called twice, first time with "France" and second time with "United States", it was the expected behavior
		 *
		 * But now, "tagRender" function is called three times with "France", "United States", and a react element that displays "Spain", with the omitted value as child.
		 * So it results in rendering a tag in a tag, which is not the expected behavior
		 */
		if (typeof customTagProps.label !== 'string') {
			return customTagProps.label as React.ReactElement;
		}

		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};

		if (props.maxCount === 1) {
			return (
				<Typography style={{ marginLeft: '8px' }} color="text.secondary" variant="buttonMediumRegular">
					{customTagProps.label}
				</Typography>
			);
		}

		return (
			<StyledTag
				$chipHeight={chipHeight}
				onMouseDown={onPreventMouseDown}
				closable={false}
				// Disabled for now while we wait for the migration of the whole application to the DropdownV2
				// closable={customTagProps.closable}
				// onClose={() => {
				// 	customTagProps.onClose();
				// 	onTagRemoved?.(customTagProps.value);
				// }}
			>
				{customTagProps.label}
			</StyledTag>
		);
	};

	const placeholderRender = () => {
		if (!placeholder) return undefined;
		if (Array.isArray(placeholder)) return placeholder.map((v) => <PlaceholderChip label={v} key={v} />);
		return options?.find((opt) => opt.value === placeholder)?.label ?? placeholder;
	};

	const readOnlyRender = useMemo<ReactNode>(() => {
		if (readOnly === false) return null;
		if (value) {
			if (typeof value === 'string') {
				return options?.find((opt) => opt.value === value)?.label ?? value;
			}
			if (Array.isArray(value)) {
				return value.map((v) => {
					const o = options?.find((opt) => opt.value === v);
					if (!o) return null;
					return (
						<StyledTag $chipHeight={chipHeight} closable={false}>
							{o.label}
						</StyledTag>
					);
				});
			}
		}
		return placeholderRender();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readOnly, value, options, chipHeight, placeholder]);

	// Mirror external value to internal value
	useEffect(() => {
		setMirroredValue(value);
	}, [value]);

	// Focus the search input after the dropdown open (add a delay to prevent rendering ordering issue)
	useEffect(() => {
		if (open) setTimeout(() => searchInputRef.current?.focus(), 200);
	}, [open]);

	// Manage dropdown state
	const handleDropdownVisibleChange = useCallback(
		(e: boolean) => {
			// Allow open if we are in forceOpen mode
			if (e && forceOpen) setOpen(e);
			// Update open state expect if we are not loading (only allow closing then) or not in forceOpen mode
			if ((!e || !loading) && !forceOpen) setOpen(e);
			if (!e) setSearchValue('');
			if (onDropdownVisibleChange) onDropdownVisibleChange(e);
			if (!e && onBlur) onBlur();
		},
		[onDropdownVisibleChange, setOpen, onBlur, loading, forceOpen],
	);

	// Clean search mirroredValue when a mirroredValue is selected
	const handleOnSelect = useCallback<NonNullable<SelectProps<ValueType, OptionType>['onSelect']>>(
		(selected, opts) => {
			if (!mode || pickList) setSearchValue('');
			if (onSelect) onSelect(selected, opts);
		},
		[mode, pickList, onSelect, setSearchValue],
	);

	// Update mirrored value when we change the selected value
	const handleOnChange = useCallback<NonNullable<SelectProps<ValueType, OptionType>['onChange']>>(
		(nextValue, opts) => {
			if (value === undefined) setMirroredValue(nextValue);

			// If the option has the isPickList tag (added internally), trigger the onNewOptionAdded
			if (pickList && (opts as any).isPickList) {
				if (onNewOptionAdded) onNewOptionAdded(nextValue);
			}
			if (onChange) onChange(nextValue, opts);
			antdSelectRef?.current?.focus?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[pickList, value, onChange],
	);

	// Calculate if the mirrored value is an empty value
	const showPlaceholder = useMemo(() => isValueEmpty(mirroredValue), [mirroredValue]);

	const handleSearchKeyDown = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			// If the searchInput is of type number, catch e, + and - key
			if (searchInputType === 'number' && ['e', '+', '-'].includes(e.key)) {
				e.preventDefault();
			}

			// Avoid moving the cursor at the end or the start when moving in the dropdown
			if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
				e.preventDefault();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchInputType, pickList, handleOnChange, searchValue, options],
	);

	// Compute options list, add searchValue if we are in picklist mode
	const selectOptions = useMemo<OptionType[]>(() => {
		const ret: OptionType[] = [];
		if (pickList && searchValue.trim() && !options?.some((o) => o.value.toLowerCase() === searchValue.toLowerCase())) {
			ret.push({
				label: searchValue,
				value: searchValue,
				isPickList: true,
			} as unknown as OptionType);
		}
		ret.push(...(options ?? []));
		return ret;
	}, [options, pickList, searchValue]);

	const dropdownRender = (menu: ReactElement) => (
		<div ref={setDropdownRef}>
			{withSearch && (
				<SearchWrapper
					searchInputType={searchInputType}
					height={listItemHeight}
					tabIndex={-1}
					onClick={() => searchInputRef.current?.focus()}
				>
					<MagnifyIconWrapper>
						<Icon size="20px" path={mdiMagnify} />
					</MagnifyIconWrapper>
					<SearchInput
						type={searchInputType}
						tabIndex={-1}
						placeholder={searchPlaceholder || (pickList ? 'Search or add' : 'Search')}
						ref={searchInputRef}
						value={searchValue}
						onChange={(e) => {
							if (preventEventPropagation) e.stopPropagation();
							setSearchValue(e.target.value);
						}}
						onKeyDown={handleSearchKeyDown}
					/>
					<IconButton tabIndex={-1} onClick={() => setSearchValue('')}>
						<Icon size="20px" path={mdiClose} />
					</IconButton>
				</SearchWrapper>
			)}
			{menu}
			{bottomActions}
		</div>
	);

	return (
		<Wrapper
			data-testdisabled={props.disabled ? 'disabled' : undefined}
			disabledMargin={disabledMargin}
			style={style}
			width={width}
			disabled={props.disabled}
			inlineLabel={inlineLabel}
			aria-label={ariaLabel ? `${ariaLabel} Dropdown Wrapper` : undefined}
			className={classNames({ inherited: variant === 'prefilled' })}
		>
			{label && (
				<Label variant="buttonLargeMedium" error={error}>
					<>
						<LabelBlockWrapper>
							{label}
							{tooltip && (
								<Tooltip title={tooltip} placement="right">
									<TooltipIcon path={mdiInformation} size="16px" />
								</Tooltip>
							)}
						</LabelBlockWrapper>
						{labelIcon}
					</>
				</Label>
			)}
			<PlaceholderWrapper inlineLabel={inlineLabel} width={width} role="combobox" aria-label={ariaLabel}>
				<DropdownPopupStyle error={error} listItemHeight={listItemHeight} />
				<WrapperInputError>
					{readOnly ? (
						<ReadOnlyField $height={height} $error={!!error}>
							{readOnlyRender}
						</ReadOnlyField>
					) : (
						<>
							<StyledSelect<
								FC<
									{
										$searchPlaceholder: string;
										ref: RefObject<RefSelectProps>;
									} & SelectProps<ValueType, OptionType>
								>
							>
								getPopupContainer={(trigger: Node) => trigger.parentElement ?? document.body}
								{...props}
								open={open}
								defaultOpen={forceOpen}
								autoFocus={props.autoFocus || forceOpen}
								showAction={['focus', 'click']}
								value={mirroredValue}
								options={selectOptions}
								mode={mode}
								ref={antdSelectRef}
								loading={loading}
								error={error}
								$stylePlacement={placement}
								$searchPlaceholder=""
								$backgroundColor={backgroundColor}
								$transparentBorder={transparentBorder}
								placeholder=""
								maxTagCount="responsive"
								dropdownAlign={{ offset: [0, 0], htmlRegion: 'visible' }}
								listHeight={170}
								listItemHeight={listItemHeight}
								height={height}
								onDropdownVisibleChange={handleDropdownVisibleChange}
								searchValue={searchValue}
								menuItemSelectedIcon={['tags', 'multiple'].includes(mode || '') ? menuItemSelectedIcon : null}
								onSelect={handleOnSelect}
								onChange={(nextValue, opts) => {
									const check = forbiddenPickListValues?.some((_v) => {
										if (typeof _v === 'string' && typeof nextValue === 'string')
											return _v.toLowerCase() === nextValue.toLowerCase();
										return false;
									});

									if (check) return;
									handleOnChange(nextValue, opts);
								}}
								tokenSeparators={[',']}
								maxTagPlaceholder={maxTagPlaceholder}
								optionFilterProp={optionFilterProp}
								// Custom render
								notFoundContent={<div />}
								dropdownRender={dropdownRender}
								tagRender={tagRender}
								suffixIcon={loading ? <Loader size={24} /> : <DownIcon className="caretIcon" />}
							/>
							<Placeholder shown={showPlaceholder}>{placeholderRender()}</Placeholder>
						</>
					)}
					{error ? <InputError message={error} /> : null}
				</WrapperInputError>
			</PlaceholderWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div<{ disabled?: boolean; disabledMargin: boolean; width?: number; inlineLabel?: boolean }>(
	({ disabledMargin, disabled, theme, width, inlineLabel }) => css`
		${disabled &&
		css`
			.ant-select-selector {
				background-color: white !important;
				cursor: not-allowed !important;
			}
		`}

		${width !== undefined &&
		css`
			width: ${numberToPx(width)};
		`}
		${!disabledMargin &&
		css`
			margin: ${theme.spacing(1.75)} 0;
		`}

    ${inlineLabel &&
		css`
			display: flex;

			${Label} {
				margin-bottom: 0;
				margin-right: ${theme.spacing(2)};
			}
		`}
	`,
);

const DropdownPopupStyle = createGlobalStyle<{
	error?: string;
	listItemHeight: number;
}>(
	({ theme, error, listItemHeight }) => css`
		// Dropdown content styling
		.ant-select-dropdown {
			border: solid 1px ${error ? theme.palette.error.main : theme.palette.blue.main};
			border-radius: 0 0 8px 8px !important;
			border-top: solid 1px ${theme.palette.light.backgroundAlt};
			box-shadow: none;
			font-style: initial;
			z-index: 1300;

			&.ant-select-dropdown-placement-topLeft,
			&.ant-select-dropdown-placement-topRight {
				border-radius: 8px 8px 0 0 !important;
				border: solid 1px ${error ? theme.palette.error.main : theme.palette.blue.main};
				border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
			}

			.ant-select-item {
				color: ${theme.palette.text.primary};
				line-height: ${numberToPx(listItemHeight)};
				padding: 0 16px;
				min-height: ${numberToPx(listItemHeight)};
			}

			// Place checkbox on left for multiple mode
			.ant-select-item-option {
				flex-direction: row-reverse !important;
			}

			// Dropdown option group
			// Option group name
			.ant-select-item-group {
				color: ${theme.palette.text.secondary};
			}
			// Option group item
			.ant-select-item-option-grouped {
				padding: 0 16px 0 32px;
			}
		}
	`,
);

const StyledSelect = styled(Select).withConfig({
	shouldForwardProp: (prop) => !['height', 'width'].includes(prop),
})<{
	open: boolean;
	height: number;
	$backgroundColor?: string;
	$transparentBorder?: boolean;
	error?: string;
	$stylePlacement?: 'top' | 'bottom';
}>(
	({ open, height, $backgroundColor, $transparentBorder, error, disabled, $stylePlacement, theme }) => css`
		.ant-select-selection-search-mirror,
		.ant-select-selection-search {
			line-height: ${numberToPx(height)};
			max-height: ${numberToPx(height)};
		}

		height: ${numberToPx(height)};
		width: 100%;

		.ant-select-selection-item {
			color: rgba(0, 0, 0, 0.5);
			background-color: transparent !important;
			height: auto;
			line-height: 0;
			visibility: visible !important;
			max-width: 100%;

			.ant-select-selection-item-content {
				max-width: 100%;
				display: flex;
				align-items: center;
			}
		}

		.ant-select-selection-overflow-item > * {
			display: flex;
			align-items: center;
		}

		.ant-select-selection-overflow-item-rest .ant-select-selection-item {
			padding-inline-start: 0 !important;
		}

		.ant-select-selection-overflow {
			width: calc(100% - 38px);
			flex: unset;
			flex-wrap: nowrap;
			padding: ${theme.spacing(0, 0, 0, 1)};
			height: 100%;
		}

		.ant-select-selection-search {
			opacity: 0;
		}

		&.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
			cursor: pointer;
			${disabled &&
			css`
				cursor: not-allowed;
			`}
			${$transparentBorder &&
			css`
				border: 1px solid transparent;
			`}
			${$backgroundColor &&
			!error &&
			css`
				background-color: ${$backgroundColor} !important;
			`}
			${error &&
			css`
				background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
				border: 1px solid ${theme.palette.error.main} !important;
				border-radius: 8px 8px 0 8px;
				color: ${theme.palette.error.text};
			`}
		}

		&.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
			padding: 0;

			${open &&
			css`
				background-color: ${error ? alpha(theme.palette.error.main, 0.05) : theme.palette.light.main} !important;
				border-bottom: none !important;
				border-radius: 8px 8px 0 0 !important;
			`}
		}

		&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			padding: 8px 16px;

			${open &&
			css`
				background-color: ${error ? alpha(theme.palette.error.main, 0.05) : theme.palette.light.main} !important;
				border-bottom: none !important;
				border-radius: 8px 8px 0 0 !important;
			`}
		}

		${open &&
		css`
			.ant-select-arrow {
				transform: rotate(180deg);
			}
		`}
		&.ant-select-open {
			.ant-select-selection-item {
				color: rgba(0, 0, 0, 0.8);
				font-weight: 600;
			}
		}

		// Override style for top placement

		${$stylePlacement === 'top' &&
		css`
			// Override style for single mode

			&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
				${open &&
				css`
					border-bottom: 1px solid ${error ? theme.palette.error.main : theme.palette.blue.main} !important;
					border-radius: 0 0 ${error ? 0 : '8px'} 8px !important;
					border-top: 1px transparent !important;
				`}
			}

			// Override style for multiple mode and picklist

			&.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
				${open &&
				css`
					border-bottom: 1px solid ${error ? theme.palette.error.main : theme.palette.blue.main} !important;
					border-top: 1px transparent !important;
					${error
						? css`
								border-radius: 0 0 0 8px !important;
						  `
						: css`
								border-radius: 0 0 8px 8px !important;
						  `}
				`}
			}
		`}
		// Fix dropdown higher in multiple mode
		&.ant-select-multiple .ant-select-selector:after {
			height: calc(100% - 4px);
		}

		&:hover .ant-select-arrow:not(:last-child) {
			opacity: 1 !important;
		}

		.ant-select-arrow {
			inset-inline-end: 13px;

			.caretIcon {
				color: rgba(0, 0, 0, 0.5);
			}
		}
	`,
);

const PlaceholderWrapper = styled.div<{ inlineLabel?: boolean; width?: number }>(
	({ width, inlineLabel }) => css`
		position: relative;
		${inlineLabel &&
		css`
			align-items: center;
			display: flex;
		`}
		${width !== undefined &&
		css`
			width: ${numberToPx(width)};
		`}
	`,
);

const Placeholder = styled.span<{ shown: boolean }>(
	({ shown }) => css`
		color: rgba(0, 0, 0, 0.5);
		display: flex;
		font-size: 14px;
		gap: 4px;
		inset-inline-end: 16px;
		inset-inline-start: 16px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		${!shown &&
		css`
			visibility: hidden;
		`}
	`,
);

const StyledTag = styled(Tag)<{
	$chipHeight: number;
	$overflowChip?: boolean;
}>(
	({ theme, $chipHeight, $overflowChip = false }) => css`
		background-color: ${alpha(theme.palette.dark.main, 0.05)};
		border: 1px solid ${alpha(theme.palette.dark.main, 0.1)};
		color: ${alpha(theme.palette.dark.main, 0.6)};
		font-weight: 500 !important;
		height: ${numberToPx($chipHeight)};
		line-height: ${numberToPx($chipHeight - 2)};
		margin: 0 4px 0 0;
		max-width: 100%;

		overflow: hidden;
		padding: 0 ${theme.spacing(1)};
		text-overflow: ellipsis;
		white-space: nowrap;

		${$overflowChip &&
		css`
			align-self: center;
			background-color: ${theme.palette.info.main};
			border: none;
			color: ${theme.palette.light.main};
			font-weight: 500;
			height: 22px;
			line-height: 20px;
			margin: 0;
			min-width: 24px;
			padding: 1px 5px;
		`}
	`,
);

const MagnifyIconWrapper = styled.div(
	() => css`
		height: 20px;
		width: 20px;
	`,
);

type TagRender = SelectProps['tagRender'];

const StyledCheckbox = styled(Checkbox)(
	({ theme }) => css`
		height: 18px;
		margin-right: ${theme.spacing(1)};
		width: 18px;
	`,
);

const menuItemSelectedIcon = ({ isSelected }: { isSelected: boolean }) => (
	<StyledCheckbox checked={isSelected} tabIndex={-1} />
);

const isValueEmpty = (value: any): boolean =>
	value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
