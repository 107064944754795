import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import { SortingState } from '@tanstack/react-table';
import { mdiUpload } from '@mdi/js/commonjs/mdi';
import { NetworkStatus } from '@apollo/client';

// HOOKS
import { ErrorAlert } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useStateToURLSync, useURLToStateSync } from '../../../../utils/hooks/useURLQuery';
import { useURLSearchParams } from '../../../../utils/hooks/useURLSearchParams';
import useSearchTracks from '../../../../utils/hooks/tracks/useSearchTracks';
import useDebounce from '../../../../utils/hooks/useDebounce';
import { useStateQuery } from '../../../../utils/hooks/useStateQuery';

// LIBRARY
import Button from '../../../../components/library/Button';
import TextInput from '../../../../components/library/TextInput';
import FullTable from '../../../../components/table/FullTable';
import fakeTracks from './fakeTracks.json';
import ExpandableTitle from '../../../../components/library/SimpleTable/ExpandableTitle';
import TrackDetails from './TrackDetails';
import {
	dataToFilterFields,
	FilterField,
	FilterStepWithId,
	prepareDataForAPI,
} from '../../../../utils/table/filtersUtils';
import getColumns from './table/columns';
import IngestModal from './ingestModal';

const SearchInput = styled(TextInput)(
	() => css`
		width: 100%;
	`,
);

const Wrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)};
	`,
);

const UploadTracksButton = styled(Button)(
	() => css`
		height: 32px;
		min-width: auto;
		white-space: nowrap;
	`,
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function flatToNested(rows: any[]) {
	const audioTracks = rows?.filter((row) => row.trackType === 'AUDIO_TRACK') || [];
	const videoTracks = rows?.filter((row) => row.trackType === 'VIDEO_TRACK') || [];
	const subTitleTracks = rows?.filter((row) => row.trackType === 'SUBTITLE_TRACK') || [];

	return [
		...(videoTracks?.length > 0
			? [
					{
						title: <ExpandableTitle title="Video" titlesNumber={videoTracks?.length} />,
						subRows: videoTracks,
					},
			  ]
			: []),
		...(audioTracks?.length > 0
			? [
					{
						title: <ExpandableTitle title="Audio" titlesNumber={audioTracks?.length} />,
						subRows: audioTracks,
					},
			  ]
			: []),
		...(subTitleTracks?.length > 0
			? [
					{
						title: <ExpandableTitle title="Subtitles" titlesNumber={subTitleTracks?.length} />,
						subRows: subTitleTracks,
					},
			  ]
			: []),
	];
}

type Track = any;

interface TracksTabProps {
	wrapperRef: React.RefObject<HTMLDivElement>;
}

function TracksTab({ wrapperRef }: TracksTabProps) {
	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);
	const [isIngestModalOpen, setIsIngestModalOpen] = useState<boolean>(false);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [fakeData, setFakeData] = useState<Track[]>(fakeTracks);
	const [searchInput, setSearchInput] = useStateQuery<string>({ queryName: 'trackSearch' });
	const debouncedSearchInput = useDebounce(searchInput, 1000);
	const [initialFilterState] = useState<FilterStep | undefined>(undefined);
	const [filtering, setFiltering] = useState<FilterStepWithId | undefined>(undefined);
	const [curatedFilters, setCuratedFilters] = useState<FilterStep | undefined>(initialFilterState);
	const [sorting, setSorting] = useState<SortingState>([{ id: 'trackType', desc: true }]);

	const { searchParams, setQuery, deleteQuery } = useURLSearchParams();
	useURLToStateSync<FilterStepWithId | undefined>('filtersTrack', setFiltering);
	// Set URL Queries on state change
	useStateToURLSync(filtering, initialFilterState, 'filtersTrack');

	const { error, data, networkStatus, handlePageSizeChange, handlePageChange } = useSearchTracks({
		searchValue: debouncedSearchInput ?? '',
		filtering: curatedFilters,
		sorting,
		pollInterval: 10000,
		titleUuid: titleUuid || '',
	});

	// Filters Fields
	const [filtersFields] = useState<FilterField[]>(
		dataToFilterFields([
			{
				id: 'trackName',
				label: 'Name',
				type: 'string',
			},
			{
				id: 'trackType',
				label: 'Track Type',
				type: 'workType',
				options: [
					{ label: 'Video', value: 'VIDEO_TRACK' },
					{ label: 'Audio', value: 'AUDIO_TRACK' },
				],
			},
			{
				id: 'asset.fileStatus',
				label: 'File Status',
				type: 'workType',
				options: [{ label: 'Available', value: 'AVAILABLE' }],
			},
			{
				id: 'asset.ingestedAt',
				label: 'Date received / Date Expected',
				type: 'date',
			},
			{
				id: 'asset.uuid',
				label: 'File Track ID',
				type: 'string',
			},
		]),
	);

	const onItemUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files?.length) {
			setUploadedFiles([...uploadedFiles, ...Array.from(event.target.files)]);
			setIsIngestModalOpen(true);
		}
	};
	useEffect(() => {
		if (filtersFields && filtering) setCuratedFilters(prepareDataForAPI({ ...filtering }, filtersFields));
		if (!filtering) setCuratedFilters(undefined);
	}, [filtersFields, filtering]);

	const paginationData = {
		pageSize: data?.searchTracks?.page?.perPage,
		onPageSizeChange: handlePageSizeChange,
		currentPage: data?.searchTracks?.page?.currentPage,
		pageCount: data?.searchTracks?.page?.totalPage,
		onPageChange: handlePageChange,
	};

	const columnsData = getColumns(!data?.searchTracks?.documents?.length);

	const sortModalItems = [
		{ id: 'trackType', label: 'Track Type' },
		{ id: 'trackName', label: 'Name' },
		{ id: 'asset.ingestedAt', label: 'Date Received / Date Expected' },
		{ id: 'asset.fileStatus', label: 'File Status' },
		{ id: 'asset.uuid', label: 'File Track Id' },
	];

	const handleClickRow = (row: any, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
		if (row.subRows?.length) return;
		if (e.detail === 1) return;
		if (e.detail === 2) {
			const { uuid } = row.original;
			if (uuid) setQuery('trackDetails', `${uuid}/details`);
		}
	};

	const fakeTable = useMemo(() => {
		const dataIsReady = networkStatus === NetworkStatus.ready;
		return columnsData?.length && dataIsReady && data?.searchTracks.documents.length === 0 ? (
			<FullTable<Track>
				totalDocuments={fakeData.length}
				columns={columnsData}
				initialSorting={[]}
				cookiesName="tracks"
				data={flatToNested(fakeData)}
				onClickRow={handleClickRow}
				filterFields={[]}
				setFiltering={() => {}}
				expandedByDefault
				fullTableStyle="border"
				maxHeight={800}
			/>
		) : null;
	}, [fakeData, columnsData, networkStatus, data]);

	const table = useMemo(
		() =>
			columnsData?.length ? (
				<FullTable<Track>
					totalDocuments={data?.searchTracks?.page?.totalDocument}
					columns={columnsData}
					initialSorting={sorting}
					cookiesName="tracks"
					backendPagination={paginationData}
					data={flatToNested(data?.searchTracks?.documents || [])}
					filterFields={filtersFields}
					filtering={filtering}
					onClickRow={handleClickRow}
					networkStatus={networkStatus}
					sortModalItems={sortModalItems}
					setFiltering={setFiltering}
					sorting={sorting}
					setSorting={setSorting}
					expandedByDefault
					fullTableStyle="border"
					maxHeight={800}
				/>
			) : null,
		[networkStatus, error, data, filtersFields, filtering, setFiltering, columnsData, sorting, setSorting],
	);

	// TODO : Remove when no longer needed (fake data)
	useEffect(() => {
		if (data?.searchTracks?.documents?.length) return;

		if (debouncedSearchInput) {
			setFakeData(
				fakeTracks.filter(
					(track) =>
						track.trackName.toLowerCase().includes(debouncedSearchInput.toLowerCase()) ||
						track.asset.fileStatus.toLowerCase().includes(debouncedSearchInput.toLowerCase()) ||
						track.assignmentStatus.toLowerCase().includes(debouncedSearchInput.toLowerCase()) ||
						track.asset.ingestedAt.toLowerCase().includes(debouncedSearchInput.toLowerCase()),
				),
			);
		} else {
			setFakeData(fakeTracks);
		}
	}, [debouncedSearchInput]);

	const selectedTrack = useMemo<Track | undefined>(() => {
		const selectedTrackUuid = searchParams.trackDetails?.split('/')?.[0];
		if (!selectedTrackUuid) return undefined;
		const selectedData = data?.searchTracks?.documents?.length ? data?.searchTracks.documents : fakeData;
		if (!selectedData) return undefined;
		return selectedData.find((track) => track.uuid === selectedTrackUuid);
	}, [searchParams.trackDetails]);

	return (
		<>
			<Wrapper>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<SearchInput
						placeholder="Search tracks"
						startIcon={<Icon path={mdiMagnify} size="24px" />}
						endIcon={
							<Button $disablePadding nxstyle="tertiary-light" onClick={() => setSearchInput('')}>
								<Icon path={mdiClose} size="16px" />
							</Button>
						}
						value={searchInput}
						onChange={(event) => setSearchInput(event.target.value)}
						height={32}
					/>
					<UploadTracksButton
						nxstyle="secondary-blue"
						onClick={() => {
							inputRef?.current?.click?.();
						}}
						startIcon={<Icon path={mdiUpload} size="16px" />}
					>
						Upload Tracks
					</UploadTracksButton>
					<input
						multiple
						onChange={onItemUpload}
						ref={inputRef}
						id="fileInput"
						type="file"
						style={{ display: 'none' }}
					/>
				</div>
				{error && <ErrorAlert errorMessage={error.message} />}
				{data?.searchTracks?.documents?.length ? table : fakeTable}
			</Wrapper>
			<TrackDetails
				track={selectedTrack}
				wrapperRef={wrapperRef}
				open={searchParams.trackDetails !== undefined}
				handleClose={() => deleteQuery('trackDetails')}
			/>
			<IngestModal
				open={isIngestModalOpen}
				onClose={() => {
					if (inputRef.current) inputRef.current.value = '';
					setIsIngestModalOpen(false);
					setUploadedFiles([]);
				}}
				uploadedFiles={uploadedFiles}
			/>
		</>
	);
}

export default TracksTab;
