import React, { ReactElement, ReactNode, KeyboardEvent, useEffect, useRef, useState, useMemo } from 'react';
import { useFloating, autoUpdate, FloatingFocusManager, FloatingPortal, size } from '@floating-ui/react';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Divider, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@mdi/react';
import { mdiClose, mdiInformation, mdiMagnify, mdiTrashCan } from '@mdi/js';
import { FixedSizeList, FixedSizeList as List, ListOnItemsRenderedProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components';
import {
	Label,
	SubMessageLayoutWrapper,
	PlaceHolderText,
	Wrapper,
	DisabledWrapper,
	SelectedItemText,
	TriggerIcons,
	SearchInput,
	SearchWrapper,
	filterOptions,
	computeItemCount,
	isItemLoaded,
	LabelBlockWrapper,
} from '../DropdownUtils';
import { TooltipIcon } from '../InputUtils';
import ConditionalWrapper from '../../../utils/ConditionalWrapper';
import { Ellipsis, MenuList, MenuWrapper, TriggerWrapper } from './styles';
import { DropdownOption, DropdownProps } from './types';
import Row from './Row';
import Button from '../Button';
import escapeStringForRegexp from '../../../utils/escapeStringForRegexp';
import InputError, { WrapperInputError } from '../InputError';
import { ReadOnlyField } from '../ReadOnlyField';

const ResetActionWrapper = styled.div(
	({ theme }) => `
		display: flex;
		height: 44px !important;
		justify-content: flex-end;
		align-items: center;
		padding-right: ${theme.spacing(1)};
`,
);

function Dropdown(props: DropdownProps) {
	const {
		options,
		ariaLabel,
		defaultOptions,
		value,
		onChange,
		label,
		maxHeight,
		inlineLabel,
		readOnly,
		disabled,
		searchPlaceHolder,
		tooltip,
		placeholder,
		placeholderStyle,
		error,
		className,
		height,
		enablePortal,
		verticalPadding,
		forceActive,
		bottomActions,
		preventEventPropagation,
		width,
		transparentBorder,
		portalMaxHeight,
		portalRef,
		valueStyle = 'default',
		placement = 'bottom',
		disabledMargin = false,
		canReset,
		infiniteLoaderProps,
		backendSearch,
		withSearch = true,
		backgroundColor,
		labelIcon,
		searchInputType = 'text',
		pickList = false,
		onNewOptionAdded,
		onKeyDown,
	} = props;
	const computeDisplayValue = () => {
		const option = options.find((item) => item.value === value);
		const defaultOption = defaultOptions?.find((item) => item.value === value);

		if (option) return option.label;
		if (defaultOption) return defaultOption.label;
		return '';
	};
	const [active, setActive] = useState(forceActive || false);
	const [searchValue, setSearchValue] = useState('');
	const [displayValue, setDisplayValue] = useState<string | ReactNode>(useMemo(() => computeDisplayValue(), []));
	const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>([]);
	const [isFloatingOpen, setIsFloatingOpen] = useState<boolean>(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
	const listRef = useRef<FixedSizeList>(null);
	const menuEl = useRef<HTMLUListElement>(null);
	const menuWrapperEl = useRef<HTMLDivElement>(null);
	const searchInputEl = useRef<HTMLInputElement>(null);

	const { refs, floatingStyles, context } = useFloating({
		open: isFloatingOpen,
		onOpenChange: setIsFloatingOpen,
		middleware: [
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width - 2}px`,
					});
				},
			}),
		],
		placement,
		whileElementsMounted: autoUpdate,
	});

	const itemCount = computeItemCount(
		infiniteLoaderProps,
		!!backendSearch,
		defaultOptions?.length || 0,
		searchValue.length,
		filteredOptions.length,
		options.length,
	);

	useEffect(() => {
		if (!!backendSearch && searchValue !== backendSearch.searchValue) {
			backendSearch.setSearchValue(searchValue);
		}
	}, [searchValue]);

	useEffect(() => {
		if (searchValue !== '' && highlightedIndex < 0) setHighlightedIndex(0);
		if (searchValue !== '' && itemCount !== 0 && highlightedIndex >= itemCount) setHighlightedIndex(itemCount - 1);
		if (searchValue === '') setHighlightedIndex(options.findIndex((option) => option.value === value));
	}, [searchValue, itemCount]);

	useEffect(() => {
		if (!!backendSearch && searchValue !== backendSearch.searchValue) {
			setSearchValue(backendSearch.searchValue);
		}
	}, [backendSearch?.searchValue]);

	useEffect(() => {
		if (searchValue.length && !backendSearch) {
			try {
				const regexStr = `.*${escapeStringForRegexp(searchValue).toLowerCase()}.*`;
				const searchRegex = new RegExp(regexStr);
				let updatedOptions = options.filter(filterOptions(searchRegex));

				// Picklist logic
				if (pickList) {
					const searchExists = updatedOptions.some(
						(option) => (option.label as string).toLowerCase() === searchValue.toLowerCase(),
					);
					if (!searchExists) {
						updatedOptions = [{ label: searchValue, value: searchValue }, ...updatedOptions];
					}
				}

				setFilteredOptions(updatedOptions);
			} catch (e) {
				console.error(e);
			}
		}
	}, [searchValue, options, pickList]);

	useEffect(() => {
		setHighlightedIndex(options.findIndex((option) => option.value === value));
	}, [value]);

	useEffect(() => {
		setDisplayValue(computeDisplayValue());
	}, [value, options]);

	const handleSelect = (selected: string | null) => {
		/*
			WorkAround to avoid going through each list item in the focus tabIndex after select
			To avoid this we force focus the last element in the menu
		*/
		if (menuEl.current) {
			const lastChild = menuEl.current.children[menuEl.current.children.length - 1] as HTMLElement;
			if (lastChild) lastChild.focus();
		}
		// Resetting scroll to the beginning of the list
		if (menuWrapperEl.current) menuWrapperEl.current.scroll(0, 0);

		if (pickList && selected) {
			const optionExists = options.some((option) => option.value === selected);
			if (!optionExists && onNewOptionAdded) {
				onNewOptionAdded({ label: selected, value: selected });
			}
		}

		onChange(selected);
		setSearchValue('');
		setActive(false);
		if (searchInputEl.current) {
			searchInputEl.current.blur();
		}
	};

	const onKeyPressed = (e: any) => {
		let newIndex;
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			newIndex = Math.min(highlightedIndex + 1, itemCount - 1);
			setHighlightedIndex(newIndex);
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			newIndex = Math.max(highlightedIndex - 1, 0);
			setHighlightedIndex(newIndex);
		} else if (e.key === 'Enter' && highlightedIndex >= 0 && highlightedIndex < itemCount) {
			searchInputEl.current?.blur();
			// @ts-ignore
			refs.reference.current?.focus();
			if (searchValue?.length && filteredOptions?.length) {
				handleSelect(filteredOptions[highlightedIndex].value);
			} else if (defaultOptions?.length) {
				if (highlightedIndex < defaultOptions.length + 1) {
					handleSelect(defaultOptions[highlightedIndex - 1].value);
				} else {
					handleSelect(options[highlightedIndex - defaultOptions.length - 1].value);
				}
			} else {
				handleSelect(options[highlightedIndex].value);
			}
		} else if (e.key === 'Escape') {
			e.preventDefault();
			setActive(false);
		}

		if (newIndex !== undefined) {
			listRef.current?.scrollToItem(newIndex);
		}
	};

	function renderList(
		onItemsRendered?: (props: ListOnItemsRenderedProps) => any | undefined,
		ref?: (ref: any) => void | undefined,
	) {
		const sufficientHeight = itemCount * (height || 48);

		const getHeight = () => {
			if (sufficientHeight < (maxHeight || 170)) return sufficientHeight;
			return maxHeight || 170;
		};

		return (
			<List
				onItemsRendered={onItemsRendered}
				ref={ref}
				height={getHeight()}
				itemCount={itemCount}
				itemData={{
					highlightedIndex,
					options,
					isSearchBackend: !!backendSearch,
					searchValue,
					handleSelect,
					defaultOptions,
					filteredOptions,
					height,
					ariaLabel,
				}}
				itemSize={height || 48}
				width={menuWrapperEl?.current?.clientWidth!}
			>
				{Row}
			</List>
		);
	}

	const portalWrapper = (children: ReactElement) => (
		<FloatingPortal root={portalRef?.current || document.body}>
			<FloatingFocusManager context={context} modal={false} returnFocus={false}>
				{children}
			</FloatingFocusManager>
		</FloatingPortal>
	);

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!e.altKey && !e.ctrlKey && e.key.length === 1) {
			searchInputEl.current?.focus();
		}
		if (!e.altKey && !e.ctrlKey && e.key === 'Tab') {
			searchInputEl.current?.blur();
			// @ts-ignore
			refs.reference.current?.focus();
		}
	};

	return (
		<Wrapper
			disabledMargin={disabledMargin}
			inlineLabel={inlineLabel}
			disabled={disabled}
			className={className}
			width={width}
		>
			<WrapperInputError>
				{label && (
					<Label variant="buttonLargeMedium" error={error}>
						<>
							<LabelBlockWrapper>
								{label}
								{tooltip && (
									<Tooltip title={tooltip} placement="right">
										<TooltipIcon path={mdiInformation} size="16px" />
									</Tooltip>
								)}
							</LabelBlockWrapper>
							{labelIcon}
						</>
					</Label>
				)}
				{readOnly && <ReadOnlyField $height={height ?? 46}>{displayValue || placeholder || ''}</ReadOnlyField>}
				{disabled && <DisabledWrapper>{displayValue}</DisabledWrapper>}
				{!readOnly && !disabled && (
					<div>
						<SubMessageLayoutWrapper>
							<TriggerWrapper
								aria-label={ariaLabel ? `${ariaLabel} Dropdown Wrapper` : undefined}
								backgroundColor={backgroundColor}
								ref={refs.setReference}
								className="input-wrapper"
								active={active}
								tabIndex={0}
								transparentBorder={transparentBorder}
								onClick={() => {
									if (searchInputEl.current) searchInputEl.current.focus();
								}}
								onFocus={() => {
									setActive(true);
								}}
								onBlur={() => {
									setActive(false);
								}}
								onKeyDown={(e) => {
									handleKeyDown(e);
									if (onKeyDown) onKeyDown(e);
									else onKeyPressed(e);
								}}
								error={error !== undefined}
								errorMessage={(error || '')?.length > 0}
								verticalPadding={verticalPadding}
								height={height}
								placement={placement}
							>
								<Ellipsis>
									<SelectedItemText italic={valueStyle === 'italic'} active={active}>
										{displayValue || (
												<PlaceHolderText placeholderStyle={placeholderStyle}>{placeholder}</PlaceHolderText>
											) ||
											''}
									</SelectedItemText>
								</Ellipsis>
								<TriggerIcons>
									<DownIcon className="caretIcon" />
								</TriggerIcons>
								<ConditionalWrapper condition={!!enablePortal} wrapper={portalWrapper}>
									<MenuWrapper
										canReset={canReset && !!value}
										maxHeight={portalMaxHeight}
										ref={enablePortal ? refs.setFloating : menuWrapperEl}
										enablePortal={enablePortal}
										className="menu-wrapper"
										active={active}
										height={height}
										placement={placement}
										style={enablePortal ? floatingStyles : undefined}
									>
										{withSearch && (
											<SearchWrapper height={height} searchInputType={searchInputType}>
												<div style={{ width: '20px', height: '20px' }}>
													<Icon size="20px" path={mdiMagnify} />
												</div>
												<SearchInput
													type={searchInputType}
													ref={searchInputEl}
													data-testid={ariaLabel ? `${ariaLabel} Dropdown Search` : undefined}
													placeholder={searchPlaceHolder || (pickList ? 'Search or add' : 'Search')}
													value={searchValue}
													onChange={(e) => {
														if (preventEventPropagation) e.stopPropagation();
														setSearchValue(e.target.value);
													}}
													onKeyDown={(e) => {
														if (searchInputType === 'number' && ['e', '+', '-'].includes(e.key)) {
															e.preventDefault();
														}
													}}
												/>
												<IconButton onClick={() => setSearchValue('')}>
													<Icon size="20px" path={mdiClose} />
												</IconButton>
											</SearchWrapper>
										)}
										<MenuList maxHeight={maxHeight} placement={placement} ref={menuEl}>
											{infiniteLoaderProps ? (
												<InfiniteLoader
													isItemLoaded={(index) =>
														isItemLoaded(
															index,
															infiniteLoaderProps,
															!!backendSearch,
															defaultOptions,
															searchValue,
															filteredOptions,
															options,
														)
													}
													itemCount={itemCount}
													loadMoreItems={infiniteLoaderProps!.loadMoreItems}
												>
													{({ onItemsRendered, ref }) =>
														renderList(onItemsRendered, (listInstance) => {
															ref(listInstance);
															// @ts-ignore
															listRef.current = listInstance;
														})
													}
												</InfiniteLoader>
											) : (
												renderList(undefined, (listInstance) => {
													// @ts-ignore
													listRef.current = listInstance;
												})
											)}
										</MenuList>
										{canReset && !!value && (
											<>
												<Divider />
												<ResetActionWrapper>
													<Button
														onClick={() => {
															handleSelect(null);
														}}
														nxstyle="secondary-red"
													>
														<Icon path={mdiTrashCan} size="16px" />
													</Button>
												</ResetActionWrapper>
											</>
										)}
										{bottomActions}
									</MenuWrapper>
								</ConditionalWrapper>
							</TriggerWrapper>
							{error ? <InputError message={error} /> : null}
						</SubMessageLayoutWrapper>
					</div>
				)}
			</WrapperInputError>
		</Wrapper>
	);
}

export default Dropdown;
