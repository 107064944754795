import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Divider, Typography } from '@mui/material';
import { mdiMagnify, mdiClose, mdiTrayArrowDown, mdiPlus, mdiAppleKeyboardCommand } from '@mdi/js';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';
import { SortingState, VisibilityState } from '@tanstack/react-table';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ErrorAlert } from '@warehouse/shared/ui';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import OriginalLanguagesJSON from '../../assets/json-administration-profiles/availableBasicOriginalLanguage.json';

// GRAPHQL

// HOOKS
import useWindowSize, { Size } from '../../utils/hooks/useWindowSize';
import useDebounce from '../../utils/hooks/useDebounce';
import { useURLQuery } from '../../utils/hooks/useURLQuery';
import useSearchTitlesV2 from '../../utils/hooks/titles/useSearchTitlesV2';
import useTitleStatus from '../../utils/hooks/titles/useTitleStatus';
import useWorkTypes from '../../utils/hooks/titles/useWorkTypes';

// LIBRARY
import Card from '../../components/library/Card';
import TextInput from '../../components/library/TextInput';
import Button from '../../components/library/Button';
import {
	dataToFilterFields,
	FilterField,
	FilterStepWithId,
	isStep,
	prepareDataForAPI,
} from '../../utils/table/filtersUtils';
import FullTable from '../../components/table/FullTable';
import columns from './table/columns';
import { CreateTitleModal } from './createTitle';
import { ColumnDefType, convertColumnsToSortFilterModalItems } from '../../utils/table/sortingUtils';
import deepEquality from '../../utils/deepEquality';
import { useSessionShareableParams } from '../../utils/hooks/useSessionShareableParams';
import { AutoButtonGroup } from '../../components/library/AutoButtonGroup';

const Wrapper = styled.div(
	() => css`
		background-color: #dde0e3;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		width: 100%;
	`,
);

const CardWrapper = styled.div(
	({ theme }) => css`
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const TopPageWrapper = styled.div``;

const TopActionWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		height: 26px;
		justify-content: space-between;
		padding: ${theme.spacing(2)};
	`,
);

const TopActionButtonWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(2)};
		height: 32px;
	`,
);

const SearchBar = styled(TextInput)(
	({ theme }) => css`
		margin: 0;
		padding: ${theme.spacing(2)};
	`,
);

function removeIdFromFilter(filter?: FilterStepWithId): FilterStep | undefined {
	if (!filter) return undefined;
	return {
		step: {
			combinationOperator: filter.step.combinationOperator,
			filters:
				filter.step.filters.map((f) => {
					if (isStep(f)) return removeIdFromFilter(f)!;
					return {
						field: f.field,
						operator: f.operator,
						value: f.value,
						...(f.dateAuxiliary ? { dateAuxiliary: f.dateAuxiliary } : {}),
					} as Filter;
				}) ?? [],
		},
	};
}

function AdminTitlesView() {
	const POLL_INTERVAL = 500;
	const navigate = useNavigate();
	const size: Size = useWindowSize();
	const wrapperEl = useRef<HTMLDivElement>(null);
	const urlQuery = useURLQuery();
	const [createTitleModalIsOpen, setCreateTitleModalIsOpen] = useState<boolean>(!!urlQuery.get('titleCreation'));
	const [searchFields, setSearchFields] = useState<string[]>([]);

	// Refs to get Table Wrapper height for table scroll
	const topPageWrapperEl = useRef<HTMLDivElement>(null);
	const [tableHeight, setTableHeight] = useState(0);

	// Pagination
	const [perPage, setPerPage] = useSessionShareableParams<number>({
		name: 'perPage',
		uniqueKey: 'title-report',
		defaultValue: 50,
	});
	const [page, setPage] = useSessionShareableParams<number>({
		name: 'page',
		uniqueKey: 'title-report',
		defaultValue: 1,
	});

	// Filters
	const { getUuidByName } = useWorkTypes();
	const performanceWorkTypeUuid = useMemo(() => getUuidByName('Edit'), []);
	const supplementalWorkTypeUuid = useMemo(() => getUuidByName('Supplemental'), []);
	const defaultFilter = useMemo<FilterStep | undefined>(() => {
		if (!performanceWorkTypeUuid || !supplementalWorkTypeUuid) return undefined;
		return {
			step: {
				combinationOperator: 'AND',
				filters: [
					{
						field: 'workType',
						operator: 'doesNotEqual',
						value: performanceWorkTypeUuid || '',
					},
					{
						field: 'workType',
						operator: 'doesNotEqual',
						value: supplementalWorkTypeUuid || '',
					},
					{
						field: 'titleStatus',
						operator: 'doesNotEqual',
						value: 'hidden',
					},
				],
			},
		};
	}, [performanceWorkTypeUuid, supplementalWorkTypeUuid]);
	const [filter, setFilter, resetFilter] = useSessionShareableParams<FilterStepWithId | undefined>({
		uniqueKey: 'title-report',
		name: 'filter',
		defaultValue: defaultFilter,
		transformState: useCallback((f: FilterStepWithId | undefined) => removeIdFromFilter(f), []),
	});

	const isDefaultFilter = useMemo(
		() => deepEquality(defaultFilter?.step, removeIdFromFilter(filter)?.step),
		[defaultFilter, filter],
	);

	const [curatedFilters, setCuratedFilters] = useState<FilterStep | undefined>();

	// Create title options
	const createTitleOptions = useMemo(
		() => [
			{
				id: 'single',
				label: 'Create Single Title',
				icon: <Icon path={mdiAppleKeyboardCommand} size="16px" />,
				onClick: () => setCreateTitleModalIsOpen(true),
			},
			{
				id: 'multiple',
				label: 'Create Multiple Titles',
				icon: <Icon path={mdiAppleKeyboardCommand} size="16px" />,
				onClick: () => navigate('/titles/create'),
			},
		],
		[],
	);

	// Title types
	const { options: workTypeOptions, workTypeDetailsOptions } = useWorkTypes();
	// Title Status
	const { options: titleStatusOptions } = useTitleStatus();

	// Sorting
	const defaultSorting = useMemo(() => [{ id: 'titleSearchSortFull', desc: false }], []);
	const [sorting, setSorting, resetSort] = useSessionShareableParams<SortingState>({
		uniqueKey: 'title-report',
		name: 'sort',
		defaultValue: defaultSorting,
	});
	const showResetSortButton = useMemo(() => !deepEquality(sorting, defaultSorting), [sorting, defaultSorting]);

	// Search
	const [searchInput, setSearchInput, resetSearchInput] = useSessionShareableParams<string>({
		uniqueKey: 'title-report',
		name: 'titleSearch',
		defaultValue: '',
	});
	const debouncedSearch = useDebounce(searchInput, 500);

	// Dynamic table height
	useEffect(() => {
		if (topPageWrapperEl.current) {
			setTableHeight((size?.height || 1000) - topPageWrapperEl.current.clientHeight - 109);
		}
	}, [topPageWrapperEl?.current?.clientHeight, size]);

	const { error, data, networkStatus } = useSearchTitlesV2({
		debouncedSearch,
		filtering: curatedFilters,
		sorting,
		pollInterval: POLL_INTERVAL,
		page,
		perPage,
		searchFields,
	});

	useEffect(() => {
		setPage(1);
	}, [debouncedSearch]);

	// Filters Fields
	const [filtersFields] = useState<FilterField[]>(
		dataToFilterFields([
			{
				id: 'uuid',
				label: 'ID',
				type: 'string',
			},
			{
				id: 'licensor.label',
				label: 'Licensor',
				type: 'string',
			},
			{
				id: 'aspectRatio.aspectRatio',
				label: 'Aspect Ratio',
				type: 'float',
			},
			{
				id: 'frameRate.frameRate',
				label: 'Frame Rate',
				type: 'int',
			},
			{
				id: 'summary190',
				label: 'Summary 190',
				type: 'string',
			},
			{
				id: 'summary400',
				label: 'Summary 400',
				type: 'string',
			},
			{
				id: 'summary4000',
				label: 'Summary 4000',
				type: 'string',
			},
			{
				id: 'titleSearchDisplayNameFull',
				label: 'Title',
				type: 'string',
			},
			{
				id: 'workType',
				label: 'Title Type',
				type: 'workType',
				options: workTypeOptions,
			},
			{
				id: 'workTypeDetails',
				label: 'Title Type Detail',
				type: 'workTypeDetails',
				options: workTypeDetailsOptions,
			},
			{
				id: 'releaseYear',
				label: 'Release Year',
				type: 'date',
			},
			{
				id: 'releaseDate',
				label: 'Release Date',
				type: 'date',
			},
			{
				id: 'titleAbbreviated',
				label: 'Abbreviated Title',
				type: 'string',
			},
			{
				id: 'runLength',
				label: 'Run Length',
				type: 'string',
			},
			{
				id: 'eidrId',
				label: 'EIDR-S ID',
				type: 'string',
			},
			{
				id: 'titleDisplayUnlimited',
				label: 'Display Title',
				type: 'string',
			},
			{
				id: 'titleSort',
				label: 'Sort Title',
				type: 'string',
			},
			{
				id: 'originalTitle',
				label: 'Original Title',
				type: 'string',
			},
			{
				id: 'sequenceNumber',
				label: 'Sequence Number',
				type: 'int',
			},
			{
				id: 'distributionNumber',
				label: 'Distribution Number',
				type: 'string',
			},
			{
				id: 'houseSequence',
				label: 'House Sequence',
				type: 'string',
			},
			{
				id: 'titleStatus',
				label: 'Status',
				type: 'titleStatus',
				options: titleStatusOptions,
			},
			{
				id: 'createdAt',
				label: 'Created At',
				type: 'date',
			},
			{
				id: 'createdBy.name',
				label: 'Created By',
				type: 'string',
			},
			{
				id: 'editedAt',
				label: 'Last Modified',
				type: 'date',
			},
			{
				id: 'editedBy.name',
				label: 'Last Modified By',
				type: 'string',
			},
			{
				id: 'originalLanguages.uuid',
				label: 'Original Languages',
				type: 'array',
				options: OriginalLanguagesJSON.map(({ uuid, label }) => ({ value: uuid, label })),
			},
			{
				id: 'countriesOfCreation.uuid',
				label: 'Countries Of Origin',
				type: 'countries-array',
			},
			{
				id: 'countriesOfProduction.uuid',
				label: 'Production Countries',
				type: 'countries-array',
			},
		]),
	);

	// TODO(arthur): use a useMemo to compute the currated list of filter (spare one render)
	// Curate filters from Front end properties for API
	useEffect(() => {
		if (filtersFields && filter) setCuratedFilters(prepareDataForAPI({ ...filter }, filtersFields));
		if (!filter) setCuratedFilters(undefined);
	}, [filtersFields, filter]);

	const customColumns = useMemo(() => columns(), []);
	const sortModalItems = useMemo(() => convertColumnsToSortFilterModalItems(customColumns), [customColumns]);

	// Go back to first page if sorting or filters change
	useEffect(() => {
		setPage(1);
	}, [filter, sorting]);

	const refPageCount = useRef<number | undefined>(data?.searchTitlesV2?.page?.totalPage);
	if (data?.searchTitlesV2?.page?.totalPage) refPageCount.current = data?.searchTitlesV2?.page?.totalPage;
	const paginationData = {
		pageSize: perPage,
		onPageSizeChange: useCallback(
			(nextPerPage: number) => {
				setPage(1);
				setPerPage(nextPerPage);
			},
			[setPerPage, setPage],
		),
		currentPage: page,
		pageCount: refPageCount.current,
		onPageChange: useCallback(
			(nextPage: number) => {
				setPage(nextPage);
			},
			[setPage],
		),
	};

	const initialColumnSticky = useMemo(() => ['licensor', 'titleSearchDisplayNameFull'], []);
	const table = useMemo(
		() =>
			customColumns.length ? (
				<FullTable<IndexedTitleV2>
					columns={customColumns}
					getSortableColumnKeyById={(id: string) => columns().find((column) => column.id === id)?.indexedTitleKey || ''}
					initialColumnSticky={initialColumnSticky}
					initialSorting={sorting}
					cookiesName="titles"
					data={data?.searchTitlesV2.documents || []}
					height={tableHeight}
					filtering={filter}
					filterFields={filtersFields}
					setFiltering={setFilter}
					resetFilter={resetFilter}
					showResetFilterButton={!isDefaultFilter}
					networkStatus={networkStatus}
					backendPagination={paginationData}
					sortModalItems={sortModalItems}
					sorting={sorting}
					setSorting={setSorting}
					resetSort={resetSort}
					showResetSortButton={showResetSortButton}
					totalDocuments={data?.searchTitlesV2.page.totalDocument}
					initialVisibility={initialVisibility}
					storageUniqueKey="title-report"
					onColumnVisibilityChange={(visibility: VisibilityState) =>
						setSearchFields(computeSearchFieldsFromColumnVisibility(visibility, customColumns))
					}
				/>
			) : null,
		[
			networkStatus,
			error,
			data,
			tableHeight,
			filtersFields,
			filter,
			setFilter,
			customColumns,
			sorting,
			setSorting,
			initialColumnSticky,
		],
	);

	return (
		<Wrapper ref={wrapperEl}>
			<CardWrapper>
				<Card sx={{ minWidth: 275, overflow: 'visible' }}>
					<TopPageWrapper ref={topPageWrapperEl}>
						<TopActionWrapper>
							<Typography variant="h4Bold">Titles</Typography>
							<TopActionButtonWrapper>
								{isFeatureEnabledForCurrentTenant('title-report-export-button') && (
									<Button
										nxstyle="secondary-black"
										size="large"
										$fullHeight
										startIcon={<Icon path={mdiTrayArrowDown} size="20px" />}
									>
										Export
									</Button>
								)}
								<AutoButtonGroup
									expandButtonAriaLabel="Expand Create Title Menu"
									nxstyle="primary-blue"
									height={32}
									options={createTitleOptions}
									startIcon={<Icon path={mdiPlus} size="20px" />}
									storeKey="title-creation"
								/>
							</TopActionButtonWrapper>
						</TopActionWrapper>
						<Divider />
						<SearchBar
							placeholder="Search titles"
							startIcon={<Icon path={mdiMagnify} size="24px" />}
							endIcon={
								<Button $disablePadding nxstyle="tertiary-light" onClick={() => resetSearchInput()}>
									<Icon path={mdiClose} size="16px" />
								</Button>
							}
							value={searchInput}
							onChange={(event) => {
								setSearchInput(event.target.value);
							}}
							height={32}
						/>
					</TopPageWrapper>
					{error && <ErrorAlert style={{ margin: 16 }} errorMessage={error.message} />}
				</Card>
				<Card sx={{ overflow: 'auto' }}>{table}</Card>
			</CardWrapper>
			<CreateTitleModal open={createTitleModalIsOpen} handleClose={() => setCreateTitleModalIsOpen(false)} />
		</Wrapper>
	);
}

const initialVisibility = {
	summary190: false,
	summary400: false,
	summary4000: false,
	houseSequence: false,
	distributionNumber: false,
	sequenceNumber: false,
	countriesOfOrigin: false,
	productionCountries: false,
	releaseYear: false,
	frameRate: false,
	aspectRatio: false,
	titleSort: false,
	workTypeDetails: false,
	originalTitle: false,
	ratings: false,
	eidrId: false,
	titleStatus: false,
	abbreviatedTitle: false,
	titleDisplayUnlimited: false,
	createdAt: false,
	editedAt: false,
	createdBy: false,
	editedBy: false,
};

function computeSearchFieldsFromColumnVisibility(
	visiblity: VisibilityState,
	tableColumns: ColumnDefType<Maybe<IndexedTitleV2>>[],
): string[] {
	return Object.keys(visiblity)
		.filter((key) => visiblity[key])
		.map((key) => tableColumns.find((c) => c.id === key)?.indexedTitleKey || '')
		.filter((key) => key !== '');
}

export default AdminTitlesView;
