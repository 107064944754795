import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import ChipDisplay from '../../../library/ChipDisplay';
import TitleEditableField from '../../TitleEditableField';
import { SelectDropdownWrapper } from './style';
import SelectDropdown from '../../../library/SelectDropdown/SelectDropdown';
import LanguagesSpokenJson from '../../../../assets/json-administration-profiles/availableLanguageSpoken.json';
import useTitleAutoSave from '../../../../views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../views/title/hooks/useMemoJsonPath';
import { fromBasic } from '../../../../utils/titleGetProperty';

function VersionLanguageField() {
	const versionLanguages = useTitleAutoSave<OptionalInherited<string[]>>({
		path: useMemoJsonPath(fromBasic(['versionLanguages'])),
		label: 'Version Languages',
	});

	const versionLanguagesString = versionLanguages?.value?.displayValue || [];
	const versionLanguageStringReadonly = versionLanguages?.readOnlyValue?.displayValue || [];

	const setVersionLanguages = (newValue: string[]) =>
		versionLanguages?.setValue((prev) => ({
			...prev,
			displayValue: newValue || [],
		}));

	const languagesSpoken = useMemo(
		() =>
			LanguagesSpokenJson.map((language) => ({
				value: language?.uuid,
				label: language?.label,
			})),
		[LanguagesSpokenJson],
	);

	const isInheritedResult = isInherited({ inheritedObject: versionLanguages?.value });

	return (
		<TitleEditableField
			label="Version Languages:"
			path="coreMetadata.basic.versionLanguages"
			readComponent={
				<ChipDisplay
					variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
					allowOverflow
					values={
						versionLanguageStringReadonly
							?.map((i) => languagesSpoken?.find((l) => l.value === i)?.label)
							.filter((item): item is string => item !== undefined) || []
					}
					width={300}
					height={26}
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }}>
					<SelectDropdown
						variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
						enablePortal
						onSelect={(value) => {
							const check = versionLanguagesString?.includes(value);

							if (check) {
								setVersionLanguages(versionLanguagesString?.filter((item) => item !== value) || []);
							} else {
								setVersionLanguages([...(versionLanguagesString || []), value]);
							}
						}}
						options={languagesSpoken}
						values={versionLanguagesString}
						height={36}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}

export default VersionLanguageField;
