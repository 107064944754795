import React, { useMemo } from 'react';

// TYPES
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { resetInheritedArrayValues, isInherited, titlePaths } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore, computeMaxListingOrder } from '@warehouse/title/domain';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { sortListingOrderNonInherited } from '../../../../src/components/titles/TitleOverview/sortListingOrder';

// LIBRARY
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import SelectDropdown from '../../../../src/components/library/SelectDropdown/SelectDropdown';

// JSON
import languagesJSON from '../../../../src/assets/json-administration-profiles/availableBasicOriginalLanguage.json';

import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import { createOriginalLanguage } from '../../core/models/original-languages';

export function OriginalLanguagesField() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const originalLanguages = useTitleAutoSave<OptionalInherited<OriginalLanguage[]>>({
		label: 'Original Languages:',
		path: useMemoJsonPath(titlePaths.originalLanguages.split('.')),
	});

	const isInheritedResult = isInherited({ inheritedObject: originalLanguages?.value });

	const maxListingOrder = useMemo(
		() => computeMaxListingOrder(originalLanguages?.value?.displayValue),
		[originalLanguages?.value?.displayValue],
	);

	return (
		<TitleEditableField
			label="Original Languages:"
			path={titlePaths.originalLanguages}
			jumpToParentButtonDisabled
			readComponent={
				<ChipDisplay
					allowOverflow
					horizontalPadding={0}
					calcWidth="calc(100% - 170px)"
					variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
					values={
						originalLanguages?.value?.displayValue
							?.sort(sortListingOrderNonInherited)
							?.map((i) => languagesJSON?.find((l) => l.uuid === i.originalLanguage)?.label)
							.filter((item): item is string => item !== undefined) || []
					}
					height={26}
					ariaLabel="Original Languages Field"
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }} aria-label="Original Languages Field">
					<SelectDropdown
						ariaLabel="Original Languages"
						onBlur={() => originalLanguages?.commit()}
						variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
						enablePortal
						onSelect={(value) => {
							const check = originalLanguages?.value?.displayValue?.some((ol) => ol.originalLanguage === value);

							if (check) {
								const found = originalLanguages?.value?.displayValue?.find((ol) => value === ol.originalLanguage);
								if (found) {
									const updatedOriginalLanguages =
										originalLanguages?.value?.displayValue?.filter((ol) => ol.originalLanguage !== value) || [];
									originalLanguages?.setValue(
										resetInheritedArrayValues<OriginalLanguage>(updatedOriginalLanguages, title),
									);
								}
							} else {
								const languageToAdd: OriginalLanguage = createOriginalLanguage({
									originalLanguage: value as string,
									listingOrder: maxListingOrder + 1,
								});
								originalLanguages?.setValue((prev) => ({
									...prev,
									displayValue: [...(prev?.displayValue || []), languageToAdd],
								}));
							}
						}}
						options={languagesJSON.map((language) => ({
							value: language.uuid,
							label: language.label,
						}))}
						values={
							originalLanguages?.value?.displayValue
								?.sort(sortListingOrderNonInherited)
								?.map((i) => i.originalLanguage) as string[]
						}
						height={36}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
