import React, { useMemo } from 'react';
import TextInput from '../../../library/TextInput';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';

interface SequenceNumberFieldProps {
	isInherited: boolean;
	sequenceNumber: string;
	setSequenceNumber: (sequenceNumber: string) => void;
	onBlur: () => void;
}

function SequenceNumberField({ sequenceNumber, setSequenceNumber, isInherited, onBlur }: SequenceNumberFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Sequence Number:"
				path="coreMetadata.basic.parents.sequenceInfo.number"
				jumpToParentButtonDisabled
				readComponent={<ReadOnlyTypography>{sequenceNumber}</ReadOnlyTypography>}
				editComponent={
					<TextInput
						type="number"
						fullWidth
						value={sequenceNumber}
						onChange={(e) => setSequenceNumber(e.target.value)}
						onBlur={onBlur}
						height={38}
						width="50%"
					/>
				}
			/>
		),
		[sequenceNumber, isInherited],
	);
}

export default SequenceNumberField;
