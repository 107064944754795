import { useEffect, useMemo } from 'react';
import { paginatorStoreSelector, usePaginatorStore } from '@warehouse/shared/ui';
import { useSearchRelationships, RelationshipFilters, RelationshipSorters } from '@warehouse/relationships/domain';
import { TitleLightWithSelectedParent } from '@warehouse/title/core';
import { searchStoreSelector, useSearchStore } from '../../shared/ui/search/search.store';

const POLL_INTERVAL = 500;
const SEARCH_FIELDS: string[] = ['licensor.label', 'titleSearchDisplayNameFull', 'releaseDate', 'workType.label'];

export interface SearchChildrenQuery {
	(args: { searchFields?: string[]; titleUuid: string; showHiddenTitles?: boolean; relationshipType?: string }): {
		data: TitleLightWithSelectedParent[];
		loading: boolean;
		error: unknown;
	};
}

export const useSearchChildrenQuery: SearchChildrenQuery = ({
	titleUuid,
	showHiddenTitles = false,
	searchFields = SEARCH_FIELDS,
	relationshipType,
}) => {
	const searchValue = useSearchStore(searchStoreSelector.searchValue);
	const actions = usePaginatorStore(paginatorStoreSelector.actions);
	const page = usePaginatorStore(paginatorStoreSelector.page);
	const perPage = usePaginatorStore(paginatorStoreSelector.perPage);

	const { data, error, isPending } = useSearchRelationships({
		perPage,
		page,
		pollInterval: POLL_INTERVAL,
		searchFields,
		searchValue,
		filtering: RelationshipFilters.childrenOf(titleUuid, !showHiddenTitles, relationshipType),
		sorting: useMemo(
			() => [
				RelationshipSorters.byEntryNumberFromParentCompilation(titleUuid),
				RelationshipSorters.byTitleSearchSortFull(),
			],
			[titleUuid],
		),
	});

	useEffect(() => {
		if (data?.page?.totalPage) actions.setTotalPages(data?.page?.totalPage);
		if (data?.page?.totalDocument) actions.setTotalCount(data?.page?.totalDocument);
	}, [actions, data?.page.totalPage, data?.page.totalDocument]);

	return {
		error,
		loading: isPending,
		data: data?.documents || [],
	};
};
