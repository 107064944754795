import { paginatorStoreSelector, usePaginatorStore } from '@warehouse/shared/ui';
import { WorkType } from '@warehouse/title/worktype';
import { useEffect } from 'react';
import { TitleLightWithSelectedParent } from '@warehouse/title/core';
import { useSearchRelationships } from '@warehouse/relationships/domain';
import { searchStoreSelector, useSearchStore } from '../../shared/ui/search/search.store';
import { useRelationshipSorters } from '../feature-list-relationships/useRelationshipSorters';
import { titleEditorStoreSelector, useTitleEditorStore } from './title-editor.store';

export const POLL_INTERVAL = 500;

export const SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.workType.label',
	'indexed.sequenceInfo.number',
	'indexed.runLength',
	'indexed.uuid',
];

export interface SearchRelationshipsProps {
	mode: 'children' | 'parents';
	workType: WorkType;
	titleUuid: string;
	showHiddenTitles?: boolean;
}

export interface SearchRelationshipsOutput {
	loading: boolean;
	data: TitleLightWithSelectedParent[];
}

export interface SearchRelationshipsQuery {
	(args: SearchRelationshipsProps): SearchRelationshipsOutput;
}

export const useSearchRelationshipsParentsOrChildren: SearchRelationshipsQuery = ({ showHiddenTitles, ...props }) => {
	const actions = usePaginatorStore(paginatorStoreSelector.actions);
	const searchValue = useSearchStore(searchStoreSelector.searchValue);
	const page = usePaginatorStore(paginatorStoreSelector.page);
	const perPage = usePaginatorStore(paginatorStoreSelector.perPage);
	const isTitleHidden = useTitleEditorStore(titleEditorStoreSelector.isTitleHidden);
	const sorting = useRelationshipSorters();

	const { isPending: relationshipsIsPending, data } = useSearchRelationships({
		filtering: {
			step: {
				combinationOperator: 'AND',
				filters: [
					{
						field: 'relationship.contentId',
						operator: 'equals',
						value: props.titleUuid,
					},
					{
						field: 'direction',
						operator: 'equals',
						value: props.mode === 'parents' ? 'child' : 'parent',
					},
					...(isTitleHidden
						? []
						: [{ field: 'indexed.titleStatus', operator: 'doesNotEqual' as const, value: 'hidden' }]),
				],
			},
		},
		searchFields: SEARCH_FIELDS,
		pollInterval: POLL_INTERVAL,
		sorting,
		searchValue,
		page,
		perPage,
	});

	useEffect(() => {
		actions.setPage(1);
	}, [actions, props.mode]);

	useEffect(() => {
		if (data?.page?.totalPage) actions.setTotalPages(data?.page?.totalPage);
		if (data?.page?.totalDocument) actions.setTotalCount(data?.page?.totalDocument);
	}, [actions, data?.page?.totalPage, data?.page?.totalDocument]);

	return {
		loading: relationshipsIsPending,
		data: data?.documents || [],
	};
};
