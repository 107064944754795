import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// CONTEXTS
import { isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import {
	AbbreviatedTitleField,
	TitleSecurityField,
	DefaultLocalizedInfo,
	ReleaseYearField,
	TitleStatusField,
	WorkTypeDetails,
	OriginalLanguages,
} from '@warehouse/title/feature-title-settings';
import { UnsavedChangesContext } from '../contexts/UnsavedChangesContext';

// LIBRARY
import { SideModal } from '../../../components/library/SideDrawer';
import { ContentWrapper, PaddingWrapper, Row, SharedRow } from './style';
import TitleType from './TitleType';

import TitleId from './TitleId';
import { PrimaryParent } from './PrimaryParent';
import ExportMECButton from './ExportMECButton';
import ExportMMCButton from './ExportMMCButton';

interface TitleSettingsProps {
	open: boolean;
	handleClose: () => void;
	data?: Title;
	wrapperRef: React.RefObject<HTMLDivElement>;
	exportedFileName?: string;
}

const GlobalStyle = createGlobalStyle`
	body {
		overflow: hidden;
	}
`;

const ExportButtonWrapper = styled.div(
	({ theme }) =>
		`
		display: flex;
		gap: ${theme.spacing(2)};
		`,
);

export default function TitleSettings({ open, handleClose, data, wrapperRef, exportedFileName }: TitleSettingsProps) {
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const overrideHandleClose = () => {
		promptUserIfUnsavedChanges(() => handleClose());
	};

	return (
		<SideModal
			wrapperRef={wrapperRef}
			side="right"
			open={open}
			handleClose={overrideHandleClose}
			title="Title Settings"
		>
			<PaddingWrapper>
				<GlobalStyle />
				<ContentWrapper>
					<TitleId titleId={data?.uuid} />
					<Row>
						<TitleType title={data} />
						<WorkTypeDetails />
					</Row>
					<Row>
						<TitleStatusField />
						<ReleaseYearField />
					</Row>
					<Row>
						<DefaultLocalizedInfo />
					</Row>
					<Row>
						<AbbreviatedTitleField />
					</Row>
					<Row>
						<TitleSecurityField />
					</Row>
					{data && <PrimaryParent title={data} />}
					<OriginalLanguages />
				</ContentWrapper>
				<ExportButtonWrapper>
					<SharedRow style={{ marginTop: '14px', width: '100%' }}>
						<ExportMECButton uuid={data?.uuid} exportedFileName={exportedFileName} />
						{isFeatureEnabledForCurrentTenant('title-settings-export-mmc-xml-button') && <ExportMMCButton />}
					</SharedRow>
				</ExportButtonWrapper>
			</PaddingWrapper>
		</SideModal>
	);
}
