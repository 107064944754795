import { defineStore } from '@warehouse/shared/util';
import { useEffect } from 'react';
import { isDerivedWorkType, TitleFull, TitleRepository, WorkType } from '@warehouse/title/core';
import { titleRepositorySingleton } from '@warehouse/title/infra';
import { StateCreator } from 'zustand';
import { UnreachableError } from '@warehouse/shared/errors';
import availContentIDNS from '../../../src/assets/json-administration-profiles/availableContentIDNamespaces.json';
import { useTitle } from './useTitle';
import sortAlphabeticallyByLabel from '../../../src/utils/sortAlphabeticallyByLabel';
import availableWorkTypeDetailsByWorkType from '../../../src/assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';

export interface TitleEditorStore {
	titleUuid: string | undefined;
	editMode: boolean;
	title: TitleFull;
	hashes: {
		alternateIdsForPoster: string;
	};
	actions: {
		setTitle: (title: TitleFull | undefined) => void;
		setTitleUuid: (titleUuid: string | undefined) => void;
		setEditMode: (editMode: boolean) => void;
		refetch: () => Promise<void>;
		editTitle: TitleRepository['editTitle'];
	};
}

export const createTitleEditorStore: StateCreator<TitleEditorStore> = (set, get) => {
	const repo = titleRepositorySingleton.get();

	return {
		titleUuid: undefined,
		editMode: false,
		/**
		 * This is a hack to make the Title be defined within the scope of the AdminTitleView.
		 * AdminTitleView has a loader that renders a spinner until the title is actually loaded.
		 */
		title: {} as TitleFull,
		actions: {
			setTitleUuid: (titleUuid: string | undefined) => {
				if (titleUuid === undefined) {
					// TODO: Set title to undefined once we fix the title typing
					set({ title: {} as TitleFull });
				}
				set({ titleUuid });
			},
			setEditMode: (editMode: boolean) => set({ editMode }),

			// TODO: Determine if we really can set the title to undefined. Maybe we want to have this type set to `Title` instead of `Title | undefined`
			// and another function to clear the title.
			setTitle: (title: TitleFull | undefined) => set({ title }),
			refetch: async () => {
				const uuid = get().titleUuid;
				if (!uuid) throw new Error('No title uuid set');
				await repo.refetchTitle(uuid);
			},
			editTitle: async (edits, options) => {
				const editedTitle = await repo.editTitle(edits, options);

				// As the queue is consumed outside of the relationship drawer, even if we provide another local title editor store to the relationship drawer (for the child),
				// the title will be edited and updated in the global store (the parent's one).
				// It causes the parent to be updated with the child's title when editing a child.
				// So we make sure that the title is the one we are editing before updating it.
				if (editedTitle.uuid === get().title.uuid) set({ title: editedTitle });

				return editedTitle;
			},
		},
		hashes: {
			get alternateIdsForPoster() {
				const { title } = get();
				if (!title.metadata.coreMetadata.basic.altIdentifiers) return '';
				return Object.values(title.metadata.coreMetadata.basic.altIdentifiers)
					.filter(({ displayValue }) => displayValue.namespace.toUpperCase() === 'IMDB')
					.map(({ displayValue }) => displayValue.identifier)
					.join('|');
			},
		},
	};
};

export const [TitleEditorStoreProvider, useTitleEditorStore] = defineStore<TitleEditorStore>(createTitleEditorStore);

export const titleEditorStoreSelector = {
	actions: (state: TitleEditorStore) => state.actions,
	titleUuid: (state: TitleEditorStore) => state.titleUuid,
	isTitleHidden: (state: TitleEditorStore) => state.title?.readonly?.titleStatus === 'hidden',
	title: (state: TitleEditorStore) => state.title,
	titleRequired: (state: TitleEditorStore) => {
		if (!state.title) throw new Error('This component should not render without a title having been set');
		return state.title;
	},
	editMode: (state: TitleEditorStore) => state.editMode,
	// TODO: Simply check that title is defined once we fix the title typing
	isPending: (state: TitleEditorStore) => !state.title?.uuid,
	hashes: {
		alternateIdsForPoster: (state: TitleEditorStore) => state.hashes.alternateIdsForPoster,
	},
	l1WorkType: (state: TitleEditorStore) => {
		const workType = titleEditorStoreSelector.workType(state);
		const parentAbstractionWorkType = titleEditorStoreSelector.parentAbstractionWorkType(state);

		if (isDerivedWorkType(workType)) {
			if (!parentAbstractionWorkType)
				throw new UnreachableError('Parent abstraction work type is required for Edit work type');
			return parentAbstractionWorkType;
		}
		return workType;
	},
	workType: (state: TitleEditorStore) => state.title?.readonly.workType,
	parentAbstractionWorkType: (state: TitleEditorStore) => state.title?.readonly.parentAbstractionWorkType,
	isL1: (state: TitleEditorStore) => !titleEditorStoreSelector.isL2(state) && !titleEditorStoreSelector.isL3(state),
	isL2: (state: TitleEditorStore) => state.title?.readonly.workType === WorkType.Edit,
	isL3: (state: TitleEditorStore) => state.title?.readonly.workType === WorkType.Manifestation,
	altIdentifiers: {
		availableNamespaces: (state: TitleEditorStore) => {
			if (titleEditorStoreSelector.isL1(state)) return availContentIDNS;
			return availContentIDNS.filter((ns) => !['IMDB', 'TMDB'].includes(ns.value));
		},
	},
	workTypeDetails: {
		availableWorkTypeDetails: (state: TitleEditorStore) => {
			const l1WorkType = titleEditorStoreSelector.l1WorkType(state);
			if (!l1WorkType) return { label: undefined, options: [] };
			const workTypeDetailLabel = availableWorkTypeDetailsByWorkType[l1WorkType]?.workTypeDetailLabel;
			const workTypeDetailOptions = availableWorkTypeDetailsByWorkType[l1WorkType]?.availableWorkTypeDetails;
			const sortedOptions = workTypeDetailOptions?.sort(sortAlphabeticallyByLabel) || [];

			return { label: workTypeDetailLabel, options: sortedOptions };
		},
	},
};

export function TitleEditorStoreEffects() {
	const { setTitleUuid, setTitle, setEditMode } = useTitleEditorStore(titleEditorStoreSelector.actions);

	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);
	const titleV2Output = useTitle({ uuid: titleUuid, skipable: true });
	const title = titleV2Output?.data;

	// Set title in store once it is loaded
	useEffect(() => {
		setTitle(title);
	}, [setTitle, title]);

	// TODO: Call teardown action on the store
	// TODO: Is this really necessary ? If setTitleUuid does everything, we don't need additional teardown logic
	// Reset store on teardown
	useEffect(
		() => () => {
			setEditMode(false);
			setTitleUuid(undefined);
		},
		[setEditMode, setTitleUuid],
	);

	return null;
}
