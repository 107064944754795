import { TitleIcon } from '@warehouse/title/ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { CombinedWorkType } from '@warehouse/title/core';
import { CheckIcon } from '../CheckIcon';
import Chip from '../../../../src/components/library/Chip';

interface ColoredTitleCardProps {
	workType: CombinedWorkType;
	title: string;
	subTitle: string | undefined;
	releaseYear: string | undefined;
	minWidth?: number;
	showPrimaryTick: boolean;
	hideReleaseYear: boolean;
}

export function ColoredTitleCard({
	minWidth,
	workType,
	title,
	subTitle,
	releaseYear,
	showPrimaryTick,
	hideReleaseYear,
}: ColoredTitleCardProps) {
	return (
		<Wrapper minWidth={minWidth}>
			<TitleIcon combinedWorkType={workType} iconSize={40} />
			<ContentWrapper>
				<Title variant="s2Regular">{title}</Title>
				<SubTitle>{subTitle}</SubTitle>
			</ContentWrapper>
			{showPrimaryTick && (
				<div style={{ padding: '8px' }} data-testid="primary-tick">
					<CheckIcon />
				</div>
			)}
			{releaseYear && !hideReleaseYear && (
				<ChipWrapper>
					<Chip chipsWidth={91} label={releaseYear} />
				</ChipWrapper>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div<{ minWidth?: number }>(
	({ theme, minWidth }) => css`
		align-items: center;
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		${minWidth &&
		css`
			min-width: ${minWidth}px;
		`}
		display: flex;
		height: 48px;
		width: 100%;
	`,
);

const Title = styled(Typography)(
	() => css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const SubTitle = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary} !important;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		flex-direction: column;
		margin: ${theme.spacing(0, 1.5)};
		overflow: hidden;
	`,
);

const ChipWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-left: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		height: 48px;
		margin-left: auto;
		padding: ${theme.spacing(0, 1)};
	`,
);
