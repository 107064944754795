import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { CellContext } from '@tanstack/react-table';
import { FieldErrors, UseFieldArrayAppend, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { computeMaxListingOrder } from '@warehouse/title/domain';
import { CustomAlert, TableWrapper } from './style';
import { Locale } from '../localeType';
import Dropdown from '../../../components/library/Dropdown/Dropdown';
import { CustomColumnDef } from '../../../components/library/SimpleTable/customColumnDef';
import SelectDropdown from '../../../components/library/SelectDropdown/SelectDropdown';
import { CellWrapper } from '../../../components/library/SimpleTable/style';
import localesJSON from '../../../assets/json-administration-profiles/availableBasicOriginalLanguage.json';
import { TableTextInput } from '../../title/tab/productMetadata/style';
import sortAlphabeticallyByLabel from '../../../utils/sortAlphabeticallyByLabel';
import useTooltip from '../../../utils/hooks/useTooltips';
import TitleTooltip from '../../../components/titles/TitleTooltip';
import { filterRowsByIndex } from '../../../components/library/SimpleTable/tableRowUtils';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { createOriginalLanguage } from '../../../../libs/title/core/models/original-languages';

function LanguageSelector({ info, locales }: { info: CellContext<any, any>; locales: Locale[] }) {
	return (
		<CellWrapper>
			<Dropdown
				withSearch
				enablePortal
				transparentBorder
				options={
					locales?.map((locale: any) => ({
						label: locale.label,
						value: locale.uuid,
					})) || []
				}
				value={info.cell.getValue()}
				onChange={(e) => {
					info.table.options?.meta?.updateData?.(info.row.index, info.column.id, e);
				}}
			/>
		</CellWrapper>
	);
}

function ListingOrderCell(info: CellContext<any, any>, isInherited?: boolean) {
	const { getValue } = info;
	const initialValue = getValue();
	const [value, setValue] = useState<number>(initialValue);

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(evt.target.value, 10);
		setValue(Number.isNaN(newValue) ? 0 : Math.max(newValue, 0));
	};

	const onBlur = useCallback(() => {
		info.table.options?.meta?.updateData?.(info.row.index, info.column.id, value);
	}, [info.column.id, info.row.index, info.table.options?.meta, value]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					style={{ width: '126px', fontStyle: 'inherit', boxSizing: 'border-box' }}
					type="number"
					placeholder={isInherited ? value?.toString() : undefined}
					onBlur={onBlur}
					value={value?.toString() || ''}
					onChange={onChange}
				/>
			</CellWrapper>
		),
		[isInherited, value, onBlur],
	);
}

function ModeSelector({ info }: { info: CellContext<any, any> }) {
	const { spoken, written, signed } = info.row.original;

	const switchToTrueOrUndefined = (value: boolean): true | undefined => {
		if (!value) return true;
		return undefined;
	};

	const handleSelect = (value: string) => {
		const updatedValues = {
			spoken: value === 'spoken' ? switchToTrueOrUndefined(spoken) : spoken,
			written: value === 'written' ? switchToTrueOrUndefined(written) : written,
			signed: value === 'signed' ? switchToTrueOrUndefined(signed) : signed,
		};

		info?.table?.options?.meta?.updateData?.(info.row.index, 'object', updatedValues);
	};

	const selectedModes = [];
	if (spoken) selectedModes.push('spoken');
	if (written) selectedModes.push('written');
	if (signed) selectedModes.push('signed');

	return (
		<CellWrapper>
			<SelectDropdown
				enablePortal
				transparentBorder
				options={[
					{ value: 'spoken', label: 'Spoken' },
					{ value: 'written', label: 'Written' },
					{ value: 'signed', label: 'Signed' },
				]}
				values={selectedModes}
				onSelect={handleSelect}
			/>
		</CellWrapper>
	);
}

export type OriginalLanguageCreationType = {
	listingOrder: number;
	originalLanguage: string;
	id: string;
	spoken: boolean;
	written: string;
	signed: boolean;
};

interface LanguagesTableProps {
	errors: FieldErrors<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	appendLanguage: UseFieldArrayAppend<any, any>;
	graphQLError?: string;
	languages: OriginalLanguageCreationType[];
	inheritedValues?: OriginalLanguage[];
	tableVariant: 'default' | 'prefilled';
	controlName: string;
	label: string;
	isRequired?: boolean;
}

function getErrorMessage(err: any) {
	if (typeof err?.message === 'string') {
		return err?.message;
	}
	if (Array.isArray(err)) {
		return err.map((e, index) => `Row ${index}: ${JSON.stringify(e)}`).join('\n');
	}
	return undefined;
}

function getNewListingOrder(languages: OriginalLanguageCreationType[], index: number): number {
	const maxListingOrder = computeMaxListingOrder(languages);
	return maxListingOrder + 1 + index;
}

function OriginalLanguagesTable({
	errors,
	languages,
	graphQLError,
	setValue,
	appendLanguage,
	inheritedValues,
	trigger,
	controlName,
	tableVariant,
	isRequired = false,
	label,
}: LanguagesTableProps) {
	const locales = localesJSON.sort(sortAlphabeticallyByLabel);
	const languageTooltip = useTooltip('coreMetadata.basic.originalLanguages.originalLanguage');
	const listingOrderTooltip = useTooltip('coreMetadata.basic.originalLanguages.listingOrder');
	const originalLanguagesTooltipPath = 'coreMetadata.basic.originalLanguages';

	const columns: CustomColumnDef<OriginalLanguageCreationType>[] = useMemo(
		() => [
			{
				header: 'Listing Order',
				id: 'listingOrder',
				tooltip: listingOrderTooltip?.tooltip,
				accessorFn: (row) => row?.listingOrder,
				width: 100,
				renderReadOnly: (value) => value?.displayValue,
				cell: (info) => ListingOrderCell(info, false),
			},
			{
				header: 'Language',
				accessorKey: 'originalLanguage',
				id: 'originalLanguage',
				tooltip: languageTooltip?.tooltip,
				renderReadOnly: (value: string) => locales?.find((locale) => locale.uuid === value)?.label,
				cell: (info) =>
					LanguageSelector({
						info,
						locales: locales || [],
					}),
			},
			{
				header: 'Mode',
				id: 'mode',
				cell: (info) => ModeSelector({ info }),
			},
		],
		[languageTooltip?.tooltip, listingOrderTooltip?.tooltip, locales],
	);

	useEffect(() => {
		if (languages?.length && isRequired && !inheritedValues?.length) {
			trigger(controlName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languages]);

	return (
		<TableWrapper>
			<Typography style={{ marginBottom: 16 }} color="text.secondary" variant="buttonLargeMedium">
				{label}
				<TitleTooltip path={originalLanguagesTooltipPath} />
			</Typography>
			{(errors?.[controlName] || graphQLError) && (
				<CustomAlert severity="error">{(getErrorMessage(errors[controlName]) || graphQLError) as string}</CustomAlert>
			)}
			<InheritanceOnValueChangeSimpleTableWrapper
				ariaLabel="Original Languages Table"
				editMode
				columns={columns}
				data={languages}
				setData={async (data) => {
					if (typeof data === 'function') {
						// @ts-ignore
						const newValue = data(languages);
						setValue(controlName, newValue);
					} else {
						setValue(controlName, data);
					}
				}}
				variant={tableVariant}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				showBottomActionsBar
				tableStyle="border"
				onRowDelete={async (indexes) => {
					setValue(controlName, filterRowsByIndex(languages, indexes));
					if (isRequired && !inheritedValues?.length) {
						await trigger(controlName);
					}
				}}
				onRowAdd={async (numberOfRows) => {
					for (let i = 0; i < numberOfRows; i += 1) {
						appendLanguage(
							createOriginalLanguage({
								listingOrder: getNewListingOrder(languages, i),
							}),
						);
					}
					if (isRequired && !inheritedValues?.length) {
						await trigger(controlName);
					}
				}}
			/>
		</TableWrapper>
	);
}

export default OriginalLanguagesTable;
