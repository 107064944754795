import { SearchFilters } from '../../title/domain/SearchFilters';

export class RelationshipFilters extends SearchFilters {
	static childrenOf(titleUuid: string, excludeHidden?: boolean, relationshipType?: string): FilterStep {
		return {
			step: {
				combinationOperator: 'AND',
				filters: [
					...(relationshipType
						? [
								{
									field: 'relationship.type',
									operator: 'equals' as const,
									value: relationshipType,
								},
						  ]
						: []),
					{
						field: 'relationship.contentId',
						operator: 'equals',
						value: titleUuid,
					},
					{
						field: 'direction',
						operator: 'equals',
						value: 'parent',
					},
					...(excludeHidden
						? [
								{
									field: 'indexed.titleStatus',
									operator: 'doesNotEqual' as const,
									value: 'hidden',
								},
						  ]
						: []),
				],
			},
		};
	}
}
