import { useLocation, useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { AddChildrenPrompt, AddChildrenStoreProvider } from '@warehouse/title/feature-add-children';
import { TitleFull as Title } from '@warehouse/title/core';
import { getRelationshipType } from '@warehouse/title/infra';
import { AutoButtonGroup } from '../../../../components/library/AutoButtonGroup';
import { CreateTitleModal } from '../../../titles/createTitle';
import { ChildrenTabNameOutput } from '../../hooks/useChildrenTabName';
import getDeepProperty from '../../../../utils/getDeepProperty';
import { fromBasic } from '../../../../utils/titleGetProperty';

interface CreateRelationshipsButtonProps {
	title: Title;
	tabProperties: ChildrenTabNameOutput;
}

export function CreateRelationshipsButton({ title, tabProperties }: CreateRelationshipsButtonProps) {
	const [addChildrenModalIsOpen, setAddChildrenModalIsOpen] = useState<boolean>(false);
	const [createTitleModalIsOpen, setCreateTitleModalIsOpen] = useState<boolean>(false);
	const workTypeUuid = useMemo(() => getDeepProperty<string>(title, fromBasic(['workType'])), [title]);
	const navigate = useNavigate();
	const location = useLocation();

	const handleAddChildrenClick = (id: string) => {
		switch (id) {
			case 'add-single':
				setCreateTitleModalIsOpen(true);
				break;
			case 'add-multiple':
				navigate(`/titles/create?selectedParentUuid=${title.uuid}&selectedTitleTypeUuid=${tabProperties.uuid}`);
				break;
			case 'add-existing':
				setAddChildrenModalIsOpen(true);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<AutoButtonGroup
				nxstyle="secondary-blue"
				onOptionClick={handleAddChildrenClick}
				storeKey={`title-add-children-${workTypeUuid}`}
				height={34}
				expandButtonAriaLabel="Expand Add Children Options"
				options={useMemo(
					() => [
						...(tabProperties.addChildButtonOptions.includes('add-single')
							? [
									{
										id: 'add-single',
										default: true,
										icon: <Icon path={mdiPlus} size="18px" />,
										label: `Create Single ${tabProperties.singularLabel}`,
									},
							  ]
							: []),
						...(tabProperties.addChildButtonOptions.includes('add-multiple')
							? [
									{
										id: 'add-multiple',
										icon: <Icon path={mdiPlus} size="18px" />,
										label: `Create Multiple ${tabProperties.pluralLabel}`,
									},
							  ]
							: []),
						...(tabProperties.addChildButtonOptions.includes('add-existing')
							? [
									{
										id: 'add-existing',
										icon: <Icon path={mdiPlus} size="18px" />,
										label: `Add Existing ${tabProperties.pluralLabel}`,
									},
							  ]
							: []),
					],
					[tabProperties],
				)}
			/>
			{workTypeUuid && title.uuid && (
				<AddChildrenStoreProvider>
					<AddChildrenPrompt
						titleUuid={title.uuid}
						open={addChildrenModalIsOpen}
						onClose={() => setAddChildrenModalIsOpen(false)}
						forceRelationshipType={getRelationshipType(tabProperties.relationshipType)}
						searchAndSelectedPluralLabel={tabProperties.pluralLabel}
						headerText={`Add Existing ${tabProperties.pluralLabel}`}
					/>
				</AddChildrenStoreProvider>
			)}
			<CreateTitleModal
				/**
				 * This is a hack to force the modal to re-render when the location changes
				 * This is necessary because the modal has a form with default values. Default values are set in the first mount of the modal
				 * according to the given props, but they are not updated when props change.
				 *
				 * When a user goes to Page 1, then Page 2, then Page 1 again, the modal will still have the default values from Page 2
				 * because the Page 1 was in the React router cache, so the component was not unmounted and remounted.
				 */
				key={location.pathname}
				open={createTitleModalIsOpen}
				closeOnCreate
				handleCancel={() => {
					setCreateTitleModalIsOpen(false);
				}}
				handleClose={() => setCreateTitleModalIsOpen(false)}
				selectedParentUuid={title.uuid || undefined}
				parentTitleProperties={tabProperties}
			/>
		</>
	);
}
