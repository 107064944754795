import { gql } from '@apollo/client';

export const titleIndexedFragment = gql`
	fragment TitleIndexedFragment on IndexedTitleV2 {
		aspectRatio {
			aspectRatio
			original
		}
		countriesOfCreation {
			label
			uuid
		}
		countriesOfProduction {
			label
			uuid
		}
		createdAt
		createdBy {
			name
			userId
		}
		distributionNumber
		editClasses
		editUse
		editedAt
		editedBy {
			name
			userId
		}
		eidrId
		frameRate {
			frameRate
			original
		}
		genres {
			label
			uuid
			name
		}
		houseSequence
		licensor {
			label
			uuid
		}
		madeForRegions {
			label
			uuid
		}
		madeForRegionsExclude {
			label
			uuid
		}
		originalLanguages {
			label
			uuid
			descriptor
		}
		originalTitle
		parentAbstractionWorkType {
			uuid
			name
			label
		}
		parents {
			parentContentId
			primary
			relationshipType
			relationshipSubtypes {
				uuid
				label
			}
			sequenceInfo {
				distributionNumber {
					distributionNumber
					domain
				}
				houseSequence {
					houseSequence
					domain
				}
				number
			}
		}
		partOfCompilations {
			compilationId
			contentId
			compilationEntryClass {
				label
				uuid
			}
			compilationEntryNumber
		}
		pictureColorType
		ratings
		releaseDate
		releaseYear
		runLength
		sequenceInfo {
			distributionNumber {
				distributionNumber
			}
			houseSequence {
				houseSequence
			}
			number
		}
		sequenceNumber
		summary190
		summary400
		summary4000
		threeD
		titleAbbreviated
		titleDisplayUnlimited
		titleSearchDisplayNameFull
		titleSearchDisplayNameFullHTML {
			editClasses
			editUse
			identification
			madeForRegions
			madeForRegionsExclude
			parentTitleSearchDisplayNameFullForChild
			parentTitleSearchSortFullForChild
			sequenceNumber
			titleFrontEnd
			titleSortFrontEnd
			useTitleSecurity
			versionLanguages
			workType
			workTypeDetails
		}
		titleSearchSortFull
		titleSecurity
		titleSort
		titleStatus
		uuid

		versionLanguages {
			label
			uuid
		}

		workType {
			label
			uuid
			name
		}
		workTypeDetails {
			label
			uuid
			name
		}
	}
`;
