import * as Ajv from 'ajv';

// @ts-ignore
const schema: Ajv.JSONSchemaType<any> = {
	type: 'object',
	properties: {
		// Step 1
		titleType: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The title type is required' },
		},
		title: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The title is required' },
		},
		workTypeDetail: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'This field is required' },
		},
		licensor: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The licensor is required' },
		},
		relationshipType: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The type is required' },
		},
		houseSequence: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The house sequence is required' },
		},
		titleLanguage: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The title language is required' },
		},
		parentTitle: {
			type: 'object',
			properties: {
				parentType: { type: 'string' },
				title: {
					type: 'object',
					required: ['uuid'],
					errorMessage: { required: { uuid: 'The parent is required.' } },
					properties: {
						uuid: {
							type: 'string',
						},
					},
				},
			},
			required: ['title'],
		},

		// Step 2
		countriesOfOrigin: {
			type: 'array',
			items: { type: 'string' },
			minItems: 1,
			errorMessage: {
				minItems: 'At least one country of origin is required',
			},
		},
		releaseDate: { type: 'object' },
		approximateLength: {
			type: 'number',
			minimum: 1,
			errorMessage: { minimum: 'The approximate length is required' },
		},
		distributionNumber: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The distribution number is required' },
		},
		sequenceNumber: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The sequence number is required' },
		},
		sortTitle: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The sort title is required' },
		},
		originalLanguages: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					mode: {
						type: 'string',
						minLength: 1,
						errorMessage: {
							minLength: 'The mode is required',
						},
					},
					language: {
						type: 'string',
						minLength: 1,
						errorMessage: {
							minLength: 'The language is required',
						},
					},
					default: {
						type: 'boolean',
					},
				},
			},
			minItems: 1,
			errorMessage: {
				minItems: 'At least one original language is required',
			},
		},
		versionLanguages: {
			type: 'array',
			items: {
				type: 'string',
			},
			minItems: 1,
			errorMessage: {
				minItems: 'At least one version language is required',
			},
		},
		// versionLanguages: {
		// 	type: 'array',
		// 	items: {
		// 		type: 'object',
		// 		properties: {
		// 			mode: {
		// 				type: 'string',
		// 				minLength: 1,
		// 				errorMessage: {
		// 					minLength: 'The mode is required',
		// 				},
		// 			},
		// 			langTermUID: {
		// 				type: 'string',
		// 				minLength: 1,
		// 				errorMessage: {
		// 					minLength: 'The language is required',
		// 				},
		// 			},
		// 		},
		// 	},
		// 	minItems: 1,
		// 	errorMessage: {
		// 		minItems: 'At least one version language is required',
		// 	},
		// Performance
		editUse: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The edit use is required' },
		},
		madeForRegions: {
			type: 'object',
			properties: {
				madeForRegions: {
					type: 'array',
					items: { type: 'string' },
					minItems: 1,
					errorMessage: { minItems: 'The made for regions is required' },
				},
				isInclude: {
					type: 'boolean',
					default: true,
				},
			},
			errorMessage: { minLength: 'The made for region is required' },
		},
		editClass: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The edit class is required' },
		},
		colorType: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The color type is required' },
		},
	},
	required: [
		'titleType',
		'structuralType',
		'title',
		'titleLanguage',
		'parentTitle',
		'countriesOfOrigin',
		'mode',
		'releaseDate',
		'approximateLength',
		'sortTitle',
		'houseSequence',
		'distributionNumber',
		'sequenceNumber',
		'licensor',
		'originalLanguages',
		'versionLanguages',
		'editUse',
		'editClass',
		'madeForRegions',
		'workTypeDetail',
		'relationshipType',
	],
	additionalProperties: false,
};

export default schema;
