import React, { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

import { ListItemIcon, MenuItem, MenuList, alpha } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';
import { useAuth0 } from '@auth0/auth0-react';
import { isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import logo from '../../assets/logo.svg';
import Button from './Button';
import ModalMenu from './ModalMenu';

const Logo = styled.img(
	() => css`
		height: 24px;
	`,
);

const Wrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.dark.black};
		display: flex;
		height: 52px;
		padding: 0 ${theme.spacing(3)};
	`,
);

const LogoWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex: 1;
	`,
);

const LinksWrapper = styled.div(
	() => css`
		align-items: stretch;
		display: flex;
	`,
);

const ActionsWrapper = styled.div(
	() => css`
		display: flex;
		flex: 1;
		justify-content: flex-end;
	`,
);

const TenantHeaderLogo = styled.img(
	({ theme }) => css`
		display: block;
		height: auto;
		margin-right: ${theme.spacing(2)};
		max-height: 32px;
		width: auto;
	`,
);

const MenuLink = styled(NavLink)(
	({ theme }) => css`
		align-items: center;
		border-bottom: 4px solid transparent;
		border-top: 4px solid transparent;
		box-sizing: border-box;
		color: ${alpha(theme.palette.light.main, 0.7)};
		display: flex;
		font-size: 12px;
		font-weight: 500;
		height: 52px;
		justify-content: center;
		padding: 0 ${theme.spacing(3)};
		position: relative;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			background-color: ${theme.palette.dark.main};
			color: ${theme.palette.text.reverseMain};
		}
		&.active {
			border-bottom: 4px solid ${theme.palette.primary.main};
			color: ${theme.palette.light.main};
		}
	`,
);

const CustomButton = styled(Button)(
	({ theme }) => css`
		background: none;
		border-color: ${theme.palette.dark.background};
		color: ${alpha(theme.palette.light.main, 0.7)};

		svg,
		.MuiButton-startIcon svg {
			color: ${alpha(theme.palette.light.main, 0.7)};
		}

		:hover,
		:active,
		:focus {
			background: none;
			border-color: ${theme.palette.dark.background};
			color: ${alpha(theme.palette.light.main, 0.7)};

			svg,
			.MuiButton-startIcon svg {
				color: ${alpha(theme.palette.light.main, 0.7)};
			}
		}
	`,
);

const allowedQueryParamsForTitleView = [
	'perPage',
	'page',
	'filter',
	'sort',
	'titleSearch',
	'columnsVisibility',
	'columnsOrder',
	'columnsSticky',
];

function filterQueryParams(search: string, filterList: string[]): string | undefined {
	const qs = new URLSearchParams(search);
	qs.forEach((_, key) => {
		if (!filterList.includes(key)) qs.delete(key);
	});
	const nextQueryParams = qs.toString();
	return nextQueryParams ? `?${nextQueryParams}` : undefined;
}

function Navigation() {
	const { logout, user } = useAuth0();
	const { search } = useLocation();

	const buttonRef = useRef(null);
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<Wrapper>
			<LogoWrapper>
				<Logo src={logo} alt="NexSpec logo" />
			</LogoWrapper>
			<LinksWrapper>
				<MenuLink
					to={{
						pathname: '/titles',
						search: useMemo(() => filterQueryParams(search, allowedQueryParamsForTitleView), [search]),
					}}
				>
					Titles
				</MenuLink>
				{isFeatureEnabledForCurrentTenant('top-bar-profiles-button') && <MenuLink to="/profiles">Profiles</MenuLink>}
				{isFeatureEnabledForCurrentTenant('top-bar-editor-button') && <MenuLink to="/editor">Editor</MenuLink>}
				{/* /!* <MenuLink to="/ingest">Ingest</MenuLink> *!/ */}
			</LinksWrapper>
			<ActionsWrapper>
				{process.env.REACT_APP_TENANT_HEADER_LOGO && (
					<TenantHeaderLogo
						src={process.env.REACT_APP_TENANT_HEADER_LOGO}
						alt={`${process.env.REACT_APP_TENANT_NAME} logo`}
					/>
				)}

				<CustomButton
					nxstyle="secondary-black"
					startIcon={<Icon path={mdiAccountCircle} size="20px" />}
					onClick={() => setOpenMenu(true)}
				>
					<div ref={buttonRef}>
						{user?.given_name && user?.family_name ? `${user?.given_name} ${user?.family_name}` : user?.name}
					</div>
				</CustomButton>

				<ModalMenu
					disableOverlay
					position="bottom"
					open={openMenu}
					onClose={() => setOpenMenu(false)}
					anchorEl={buttonRef.current}
					content={
						<MenuList dense>
							{process.env.REACT_APP_AUTH0_SSO_SETTINGS && (
								<a
									href={process.env.REACT_APP_AUTH0_SSO_SETTINGS}
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'none', color: 'inherit' }}
								>
									<MenuItem>
										<ListItemIcon>
											<Settings fontSize="small" />
										</ListItemIcon>
										Settings
									</MenuItem>
								</a>
							)}
							<MenuItem
								onClick={() => {
									logout();
								}}
							>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
								Logout
							</MenuItem>
						</MenuList>
					}
				/>
			</ActionsWrapper>
		</Wrapper>
	);
}

export default Navigation;
